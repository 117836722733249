import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders  } from '@angular/common/http';
import {NgForm} from '@angular/forms';


import {  footerMenu } from '../../../model/footer';
import { environment } from 'src/environments/environment';
import { ProgramsService } from 'src/app/web/services/programs.service';
import { PageHeaderService } from 'src/app/web/services/page-header.service';
import { FlashnotificationService } from 'src/app/admin/services/flashnotification.service';
import { Title, Meta  } from '@angular/platform-browser';
import { ProgramService } from 'src/app/web/services/program.service';

@Component({
  selector: 'app-express-interest',
  templateUrl: './express-interest.component.html',
  styleUrls: ['./express-interest.component.scss']
})
export class ExpressInterestComponent implements OnInit {

  apiUrl = environment.apiUrl;

  imageUrl = environment.uploadFilesUrl;

  // siteKey = environment.siteKey;

  siteKey = environment.siteKey;

  showMsgFail: boolean = false;

  showMsgSuccess: boolean = false;

  //header image
  pageHeaderImage;

  sidebarMenus: footerMenu[];
  submitExpressInterestUrl = this.apiUrl + 'expressInterest';
  fileToUpload;

  public form = {
    title: null,
    first_name: null,
    last_name: null,
    country: null,
    email: null,
    phone: null,
    english_proficiency: null,
    bachelor_degree: null,
    years_of_experience: null,
    pecb_cert: null,
    list_of_pecb_certs: null,
    interest: null,
    program_interest: null,
    resume:  null,
    reason_to_become_pecb_university_student: null,
    terms_and_conditions: null,
  }

  // file input on form
  filedata:any;

  //page loader
  loader: boolean = false;

  
  
  Captcha: any;
  
  //programs items from db
  programs: any;
  //programs from data db
  programsRes: any;

  // subprograms array after program selected
  allSubPrograms: any;

  constructor(
    private programsService: ProgramsService,
    private programService: ProgramService,
    private http: HttpClient,
    private pageHeaderService: PageHeaderService,
    private flashNotification: FlashnotificationService,
    private titleService: Title,
    private meta: Meta,
  ) { }

  ngOnInit() {
    //calling methods on render
    this.getPrograms();
    this.getSideBarMenu();
    this.getPageHeader();
    this.titleService.setTitle('PECB University - EXPRESS INTEREST')
      this.meta.addTags([
        {name: 'keywords', content: 'PECB University-EXPRESS INTEREST FOR ENROLLMENT AT PECB UNIVERSITY'},
      {name: 'description', content: 'PECB University is committed to providing an experience that spawns its students’ intellectual horizon, and helping them to acquire various primed skills for successful adaptation in the business world. Your journey as a PECB University student allows you to explore multiple prominent disciplines, and prepares you to become a highly qualified professional in the chosen industry. We will continue the grit and determination to provide you with superior education while teaching relevant subjects that aid you in becoming critical, independent, and daring professionals'},
        ]);
  }


  fileEvent(e){
    this.filedata = e.target.files[0];
    //console.log(this.filedata.name);
}

  //Submit Express form interest

submitExpressInterest(expressInterestForm: NgForm) {

  var myFormData = new FormData();
  const headers = new HttpHeaders();
  let terms;
  headers.append('Content-Type', 'multipart/form-data');
  headers.append('Accept', 'application/json');
 
  if(this.form.terms_and_conditions == true) {
      terms = '1';
  } else {
    terms = '0';
  }

  myFormData.append('title', this.form.title);
  myFormData.append('first_name', this.form.first_name);
  myFormData.append('last_name', this.form.last_name);
  myFormData.append('country', this.form.country);
  myFormData.append('email', this.form.email);
  myFormData.append('phone', this.form.phone);
  myFormData.append('english_proficiency', this.form.english_proficiency);
  myFormData.append('bachelor_degree', this.form.bachelor_degree);
  myFormData.append('years_of_experience', this.form.years_of_experience);
  myFormData.append('pecb_cert', this.form.pecb_cert);
  myFormData.append('list_of_pecb_certs', this.form.list_of_pecb_certs);
  myFormData.append('interest', this.form.interest);
  myFormData.append('program_interest', this.form.program_interest);
  myFormData.append('resume', this.filedata);
  myFormData.append('reason_to_become_pecb_university_student', this.form.reason_to_become_pecb_university_student);
  myFormData.append('terms_and_conditions', terms);

  this.loader = true;  

  this.http.post(this.submitExpressInterestUrl, myFormData, {
    headers: headers
    }).subscribe(res => {
      res => this.form;
      //console.log(this.form);   
      expressInterestForm.resetForm();
      this.filedata = '';
      this.loader = false;
      this.flashNotification.showNotification('Thank you for contacting us', 'success');
      
  }, err => {
      console.log(err);
      this.loader = false;
      this.flashNotification.showNotification('Something went wrong, please try again later.', 'danger');
      
  });
}



  //FOOTER MAIN MENU
//  getSideBarMenu(): void {
//   this.footerService.getFooterMenu().subscribe(
//     sidebarMenus => this.sidebarMenus = sidebarMenus
//  )
// }

// Get programs applied for 1
getPrograms(): void {
  this.programService.getPrograms().subscribe(
    res => {
       this.programsRes = res;
       this.programs = this.programsRes.data;
       
    }
 )
}

// Display subprograms after choose Program
getProgramsIndex(i) {
  this.allSubPrograms = this.programs[i].program_track;
}

//sidebar  MENU
getSideBarMenu(): void {
  this.programsService.getSidebarMenu().subscribe(
    res => {
      this.sidebarMenus = res;
      //console.log(this.sidebarMenus);
      
    } 
 )
}


// get page header
getPageHeader(): void {
  this.pageHeaderService.getImageHeader('expressInterest').subscribe(
    res => {
      this.pageHeaderImage = res;
      // console.log(this.pageHeaderImage.image);
      

    } 
 )
}

}
