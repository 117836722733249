import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { ActivatedRoute } from '@angular/router';

import { FooterService } from '../../../services/footer.service';
import { StaffService } from '../../../services/staff.service';
import { footerMenu } from '../../../model/footer';
import { Staff, StaffItem } from 'src/app/web/model/staff';
import { PopupStaffComponent } from '../popup-staff/popup-staff.component';
import { environment } from 'src/environments/environment';
import { PagesService } from 'src/app/web/services/pages.service';
import { PageHeaderService } from 'src/app/web/services/page-header.service';

@Component({
  selector: 'app-submenu-staff',
  templateUrl: './submenu-staff.component.html',
  styleUrls: ['./submenu-staff.component.scss']
})
export class SubmenuStaffComponent implements OnInit {


  StaffProfilesRes:any;
  StaffProfiles: StaffItem[];
  StaffPageName: any;

  sidebarMenus: footerMenu[];
  sidebarMenusRes:any;

  imageUrl = environment.uploadFilesUrl;


  pageHeaderImage;

  constructor(
    private footerService: FooterService,
    private staffService: StaffService,
    public dialog: MatDialog, 
    private route: ActivatedRoute,
    private pagesService: PagesService,
    private pageHeaderService: PageHeaderService,
  ) { }

  ngOnInit() {
    this.getSideBarMenu();
    this.getStaffProfiles();
    this.getPageHeader();

     this.route.params.subscribe(params => {
      // this.StaffPageName = params['name'];
      if(params['name'] == 'university-leadership') {
        this.StaffPageName = 'Board of Directors';
      } else if( params['name'] == 'faculty-and-administration' ) {
        this.StaffPageName = 'Faculty';
      } else if( params['name'] == 'steering-committee' ) {
        this.StaffPageName = 'Steering Committee';
      } else if (params['name'] == 'the-dean') {
        this.StaffPageName = 'The Dean';
      }


      });
     // console.log(this.StaffPageName);
    
  }

  

    //FOOTER MAIN MENU
 getSideBarMenu(): void {
  this.pagesService.getSidebarMenu().subscribe(
    res => {
      this.sidebarMenus = res
    //  console.log(this.sidebarMenus);
      //console.log(this.sidebarMenus);
      
    }
 )
}

//STAFF DATA
getStaffProfiles(): void {
  this.staffService.getCategoriesStaffData().subscribe( res => {

    this.StaffProfilesRes = res    
    this.StaffProfiles = this.StaffProfilesRes.data
    console.log(this.StaffProfiles);
    
  }
    
 )

}

openDialog(dataid) {
  this.dialog.open(PopupStaffComponent, {data: {
    id: dataid,   
  }});
}

getPageHeader(): void {
  this.pageHeaderService.getImageHeader('staff').subscribe(
    res => {
      this.pageHeaderImage = res;
      // console.log(this.pageHeaderImage.image);
    } 
 )
}




}
