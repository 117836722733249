import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { environment } from '../../../environments/environment';

import { costumerSupport } from '../model/costumer';

import {
  COSTUMERSUPPORT
 } from '../data-mock';

@Injectable({
  providedIn: 'root'
})
export class ContactService {



  // $baseUrl = 'http://127.0.0.1:8000/';

  // $pathUrl = 'api/contactUs'
  // $mainUrl = this.$baseUrl + this.$pathUrl;

  apiUrl = environment.apiUrl;

  contactFormUrl = this.apiUrl+'contactUs';

  costumerSupportUrl = this.apiUrl+'contactStaff';

  public error:any = null;

  constructor(
    private http: HttpClient,
  ) { }


   //COSTUMER SUPPORT DATA
   getCostumerSupport(): Observable<any> {
    // return of(COSTUMERSUPPORT);
    return this.http.get(this.costumerSupportUrl);
  }  



  //Send contact form to db
  sendContactForm(form): Observable<any> {
    return this.http.post(this.contactFormUrl, form); 
  }
}
