import { Component, OnInit } from '@angular/core';
import { welcomeSection, welcomeIcons } from '../../../model/welcome-section';
import { HomeService } from '../../../services/home.service';

@Component({
  selector: 'app-welcome-section',
  templateUrl: './welcome-section.component.html',
  styleUrls: ['./welcome-section.component.scss']
})
export class WelcomeSectionComponent implements OnInit {

  
  welcomeSection: any;
  welcomeSectionRes;
  welcomeIcons: welcomeIcons[];
  welcomeIconsRes: any;

  

  constructor(
    private homeService: HomeService
  ) {

    
   }

  ngOnInit() {
    this.getWelcomeData();
    setTimeout(()=> this.getWelcomeDataIcons(), 2000);
    
  }

  getWelcomeData(): void {
     this.homeService.getWelcomeSectionData().subscribe( res => {
        this.welcomeSectionRes = res;
        this.welcomeSection = this.welcomeSectionRes.data[0]
       // console.log(this.welcomeSection);
        
     }
       
    )
  }

  getWelcomeDataIcons(): void {
    this.homeService.getWelcomeSectionDataIcons().subscribe( res => {
      // welcomeIcons => this.welcomeIcons = welcomeIcons
     this.welcomeIconsRes = res;
     this.welcomeIcons = this.welcomeIconsRes.data;
    }
  )
 }

 

}
