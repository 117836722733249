import { Component, OnInit } from '@angular/core';
import { NewsEventService } from '../../services/news-event.service';
import { environment } from 'src/environments/environment';
import { PageHeaderService } from '../../services/page-header.service';
import { Title, Meta  } from '@angular/platform-browser';


@Component({
  selector: 'app-news-events',
  templateUrl: './news-events.component.html',
  styleUrls: ['./news-events.component.scss']
})
export class NewsEventsComponent implements OnInit {

  searchText;
  searchTerm;
  tagName: any;

  blogNewsDataRes: any;

  blogNewsDataItem: any;

  blogNewsData: any;

  tagsRes: any;
  
  tags: any;

  imageUrl = environment.uploadFilesUrl;

  blogNewsCategoryId: any = 'all';

  pageHeaderImage;

  p: number = 1;
  pagesNumber;

  constructor(

    private newsEventService: NewsEventService,
    private pageHeaderService: PageHeaderService,
    private titleService: Title,
    private meta: Meta,

  ) { }

  ngOnInit() {
    this.getTags();
    // this.getBlogsNews();
    this.pageChanged(this.p);
    this.getPageHeader();

    this.titleService.setTitle('PECB University - News & Events')
      this.meta.addTags([
        {name: 'keywords', content: 'News & Events about Pecb University'},
        {name: 'description', content: ''},
        ]);
  }


  //pagination
  pageChanged(pageNumber: number) {
    // console.log(page);
    this.p = pageNumber;
    //Fetch blog and news

    this.newsEventService.getBlogNewsPaginate(this.p).subscribe(res=> {
      this.blogNewsDataRes = res;
      this.blogNewsData = this.blogNewsDataRes.data;
      this.pagesNumber = this.blogNewsDataRes.count;

    });
  


  }


  //Fetch blog and news
  // getBlogsNews() {
  //   this.newsEventService.getBlogNewsPaginate(this.p).subscribe(res=> {
  //     this.blogNewsDataRes = res;
  //     this.blogNewsData = this.blogNewsDataRes.data;
  //     this.pagesNumber = this.blogNewsDataRes.count;
  //     console.log(this.pagesNumber); 

  //   });
  // }

  //Fetch tags data
  getTags() {
    this.newsEventService.getTagData().subscribe(res => {
      this.tagsRes = res;
      this.tags = this.tagsRes.data;
    })
  }


  // blog news categories
  onCategories(id) {
    this.blogNewsCategoryId = id;
   // console.log(this.blogNewsCategoryId);
    
  }

  //search blog and news
  // Filter faqs
  getSearchWord() {
    this.searchText = this.searchTerm  ;
    //console.log(this.searchKeyword );
  }


  //get tag name
  onTagsClick(tagName) {

    if(this.tagName === tagName) {
      this.searchText = '';
       this.tagName = '';
       return false;
    }

    this.searchText = tagName;
    this.tagName = tagName
  }

  
  getPageHeader(): void {
    this.pageHeaderService.getImageHeader('newsEvents').subscribe(
      res => {
        this.pageHeaderImage = res;
        // console.log(this.pageHeaderImage.image);
        

      } 
   )
  }
  

}
