import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
// import { MaterialModule } from './material-module/material.module';
import { AdminModule } from '../app/admin/admin.module';
 import { WebModule } from '../app/web/web.module';
// import { OwlModule } from 'ngx-owl-carousel';



import { FormsModule, ReactiveFormsModule } from '@angular/forms';

//import { RecaptchaModule, RecaptchaFormsModule,  RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { NgxSpinnerModule } from "ngx-spinner";


import { DatePipe } from '@angular/common';

import { LeafletModule } from '@asymmetrik/ngx-leaflet';




 


@NgModule({
  declarations: [
    AppComponent,
    // NavbarComponent,
    // FooterComponent,
    // SliderComponent,
    // WelcomeSectionComponent,
    // WidgetsApplyingComponent,
    // OfferingSnapshotsComponent,
    // TopStoriesComponent,
    // HomeComponent,
    // ProgramComponent,
    // AcademicsTreeComponent,
    // NewsEventsComponent,
    // SubmenusPageComponent,
    // TextPagesComponent,
    // SubmenuStaffComponent,
    // PopupStaffComponent,
    // ExpressInterestComponent
  ],

  imports: [
    BrowserModule,
    FormsModule,
    
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    //MaterialModule,
    AdminModule,
    
    WebModule,
    //OwlModule,
    AppRoutingModule,
    //RecaptchaModule,
    //RecaptchaFormsModule,
    NgxSpinnerModule,
    LeafletModule.forRoot(),
   
 
    
  ],
  providers: [DatePipe],
  // providers: [{
  //   provide: RECAPTCHA_SETTINGS,
  //   useValue: {
  //     siteKey: '6LeJUMEUAAAAAPVzz-XWQvKyoseIASTm0Tso2OZY',
  //   } as RecaptchaSettings,
  // }],
  bootstrap: [AppComponent]
 
})
export class AppModule { }
