import { Component, Directive, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';

import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDatepicker } from '@angular/material';
import { MomentDateModule, MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';

import { MatStepper } from '@angular/material';

import { DatePipe } from '@angular/common';

import { NgxSpinnerService } from "ngx-spinner";
import { ContactService } from '../services/contact.service';
import { PageHeaderService } from '../services/page-header.service';
import { FlashnotificationService } from 'src/app/admin/services/flashnotification.service';

import { Title, Meta  } from '@angular/platform-browser';

import { ProgramService } from './../services/program.service';
import { SubmitFormPopupComponent } from '../submit-form-popup/submit-form-popup.component';



export const DateFormats = {
  parse: {
      dateInput: ['YYYY-MM-DD']
  },
  display: {
      dateInput: 'YYYY-MM-DD',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY/MM',
  },
  display: {
    dateInput: 'YYYY/MM',
    monthYearLabel: 'YYYY MMM',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY MMMM',
  },
};

 


@Directive({
  selector: '[dateFormat1]',
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})

export class CustomDateFormat2 {

 

}

@Component({
  selector: 'app-sholarship-form',
  templateUrl: './sholarship-form.component.html',
  styleUrls: ['./sholarship-form.component.scss'],
  providers: [

    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
      { provide: MAT_DATE_FORMATS, useValue: DateFormats }

  ],
})
export class SholarshipFormComponent implements OnInit {



  //header image
  pageHeaderImage;
  // image base url
  imageUrl = environment.uploadFilesUrl;

  //country
  countries: any;
  countriesRes: any;
  countriesIndex;
  states;
  states2;

  // programs
  programs: any;
  programsRes: any;

  allSubPrograms: any;

  minDate: Date;
  maxDate: Date;
  currentYear: any;

   // upload file 
   filedataDiploma: any;
   filedataPreviousDegrees: any;
   filedataEnglishSkillsProof: any;
   filedataReferenceLetterOne: any;
   filedataReferenceLetterTwo: any;
   filedataGmatGreResults: any;
   filedataRelevantProffesionalCert: any;
   filedataOther: any;


   fileOne:any;
   fileTwo:any;
   fileThree:any;
   fileFour:any;
   fileFive:any;
   fileSix:any;
   fileSeven:any;
   fileEight:any;

   fileOneError: boolean = false;
   fileTwoError: boolean = false;
   fileThreeError: boolean = false;
   fileFourError: boolean = false;
   fileFiveError: boolean = false;
   fileSixError: boolean = false;
   fileSevenError: boolean = false;
   fileEightError: boolean = false;

   //
   toefl_checkbox: any;
   ielts_checkbox: any;
   other_checkbox: any;

   //
   wordCount: any;
   words: any;


  public form = { 
    student_id: null,
    prefered_title: null,
    last_name: null,
    first_name: null,
    date_of_birth: null,
    i_am_a: null,
    country_id: null,
    street_name_no: null,
    apt: null,
    city: null,
    state: null,
    postal_code: null,
    mobile_phone: null,
    home_phone: null,
    email_address: null,
    program_id: null,
    student_status: null,
    program_study_mode: null,
    highest_education_level: null,
    full_degree_diploma_certificate: null,
    gpa_local: null,
    gpa_us: null,
    english_lang_skill_id: {
        native_speaker: null,
        previous_education: null,
        toefl: null,
        ielts: null,
        other: null,
    },
    years_of_working_in_field_of_study: null,
    current_recent_position_held: null,
    personal_statement: null,
    attachments: {
      previous_degrees: null,
      transcripts_previuos_records: null, 
      english_lang_skills_proof: null,
      reference_letter_1: null, 
      reference_letter_2: null, 
      gmat_gre_results: null,
      relevant_professional_cert: null,
      other_cert: null
    },
    full_name: null,
    signature: null,
    date_of_application: null,
    terms_and_conditions: null,
  }

  constructor(
    private _formBuilder: FormBuilder,
    private http: HttpClient,
    public datepipe: DatePipe,
    private changeDetectorRef: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private pageHeaderService: PageHeaderService,
    private flashNotification: FlashnotificationService,
    private titleService: Title,
    private meta: Meta,
    private programService: ProgramService,
    public dialog: MatDialog, 
  ) { }

  ngOnInit() {

    this.currentYear = new Date().getFullYear(),
    this.maxDate = new Date(this.currentYear - 17, 0, 0),

    //call page hader function 
    this.getPageHeader();

    // call programs applied
    this.getPrograms();

    // call countries
    this.getCountries()
  }


  // get page header
 getPageHeader(): void {
  this.pageHeaderService.getImageHeader('applicationForm').subscribe(
    res => {
      this.pageHeaderImage = res;
     // console.log(this.pageHeaderImage.image);
      

    } 
 )
}



//Add file step 3, file 1
fileEventDiploma(e){
  this.filedataDiploma = e.target.files[0];

   this.fileOne = (this.filedataDiploma.size  / (1024*1024)).toFixed(2);
 //  console.log(this.fileOne);

   if(this.fileOne > 8) {
     this.form.attachments.previous_degrees = '';
     this.fileOneError = true;
   } else {
    this.form.attachments.previous_degrees = this.filedataDiploma;
    this.fileOneError = false;
   }
}

//Add file step 3, file 2
fileEventPreviousDegrees(e){
  this.filedataPreviousDegrees = e.target.files[0];

  this.fileTwo = (this.filedataPreviousDegrees.size  / (1024*1024)).toFixed(2);
 
 
   if(this.fileTwo > 8) {
     this.form.attachments.transcripts_previuos_records = '';
     this.fileTwoError = true;
   } else {
    this.form.attachments.transcripts_previuos_records = this.filedataPreviousDegrees;
    this.fileTwoError = false;
   }
  
}

//Add file step 3, file 3
fileEventEnglishSkillsProof(e){
  this.filedataEnglishSkillsProof = e.target.files[0];

  this.fileThree = (this.filedataEnglishSkillsProof.size  / (1024*1024)).toFixed(2);
 
   if(this.fileThree > 8) {
     this.form.attachments.english_lang_skills_proof = '';
     this.fileThreeError = true;
   } else {
    this.form.attachments.english_lang_skills_proof = this.filedataEnglishSkillsProof;
    this.fileThreeError = false;
   }
}

//Add file step 3, file 4
fileEventReferenceLetterOne(e){
  this.filedataReferenceLetterOne = e.target.files[0];

  this.fileFour = (this.filedataReferenceLetterOne.size  / (1024*1024)).toFixed(2);
 
   if(this.fileFour > 8) {
     this.form.attachments.reference_letter_1 = '';
     this.fileFourError = true;
   } else {
    this.form.attachments.reference_letter_1 = this.filedataReferenceLetterOne;
    this.fileFourError = false;
   }
}

//Add file step 3, file 5
fileEventReferenceLetterTwo(e){
  this.filedataReferenceLetterTwo = e.target.files[0];

  this.fileFive = (this.filedataReferenceLetterTwo.size  / (1024*1024)).toFixed(2);
 
   if(this.fileFive > 8) {
     this.form.attachments.reference_letter_2 = '';
     this.fileFiveError = true;
   } else {
    this.form.attachments.reference_letter_2 = this.filedataReferenceLetterTwo;
    this.fileFiveError = false;
   }
}

//Add file step 3, file 6
fileEventGmatGreResults(e){
  this.filedataGmatGreResults = e.target.files[0];

  this.fileSix = (this.filedataGmatGreResults.size  / (1024*1024)).toFixed(2);
 
   if(this.fileSix > 8) {
     this.form.attachments.gmat_gre_results = '';
     this.fileSixError = true;
   } else {
    this.form.attachments.gmat_gre_results = this.filedataGmatGreResults;
    this.fileSixError = false;
   }
}

//Add file step 3, file 7
fileEventRelevantProffesionalCert(e){
  this.filedataRelevantProffesionalCert = e.target.files[0];

  this.fileSeven = (this.filedataRelevantProffesionalCert.size  / (1024*1024)).toFixed(2);
 
   if(this.fileSeven > 8) {
     this.form.attachments.relevant_professional_cert = '';
     this.fileSevenError = true;
   } else {
    this.form.attachments.relevant_professional_cert = this.filedataRelevantProffesionalCert;
    this.fileSevenError = false;
   }
}

//Add file step 3, file 8
fileEventOther(e){
  this.filedataOther = e.target.files[0];

  this.fileEight= (this.filedataOther.size  / (1024*1024)).toFixed(2);
 
   if(this.fileEight > 8) {
     this.form.attachments.other_cert = '';
     this.fileEightError = true;
   } else {
    this.form.attachments.other_cert = this.filedataOther;
    this.fileEightError = false;
   }
}


openDialog() {
 


  var attachments: any;
  attachments = {fileDataDiploma: this.filedataDiploma, filedataPreviousDegrees: this.filedataPreviousDegrees, filedataEnglishSkillsProof: this.filedataEnglishSkillsProof, filedataReferenceLetterOne: this.filedataReferenceLetterOne, filedataReferenceLetterTwo: this.filedataReferenceLetterTwo, filedataGmatGreResults: this.filedataGmatGreResults, filedataRelevantProffesionalCert: this.filedataRelevantProffesionalCert, filedataOther: this.filedataOther} ;

  this.dialog.open(SubmitFormPopupComponent, {data: {
    form: this.form,
    programs: this.programs,
    countries: this.countries,
    states: this.states,  
    attachments: attachments,
    fileData: this.filedataDiploma
  }});


}

// Get programs applied for 
getPrograms(): void {
  this.programService.getPrograms().subscribe(
    res => {
       this.programsRes = res;
       this.programs = this.programsRes.data;
       this.allSubPrograms = this.programs[0]; 
      // console.log(this.allSubPrograms);
    }
 )
}


//Get Countries
getCountries(): void {
  this.programService.getCountriesApi().subscribe(
  res => {
    this.countriesRes =res;
    this.countries = this.countriesRes.data;
    //console.log(this.countries);
  }
 
  )
}

// Get countries states 
getCountriesIndex(i) {
  this.countriesIndex = i;
  
  this.states = this.countries[i].states;
 
}


// Count word on personal statment step
wordCounter() {
   this.wordCount = this.form.personal_statement ? this.form.personal_statement.split(/\s+/g) : 0;
   
  // console.log(this.form.personal_statement.split(/\s+/g))
   this.words = this.wordCount ? this.wordCount.length : 0;
}

// Stepper Back function
goBack(stepper: MatStepper) {
  stepper.previous();
}

}
