import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NewsEventService {

  apiUrl = environment.apiUrl;

  tagsUrl = this.apiUrl+'tag';

  blogsEventsUrl = this.apiUrl+ 'blogNewsPartial';
  paginationBlogNews

  blogsEventsPaginateUrl = this.apiUrl+ 'paginationBlogNews/';

  blogsEventsItemsUrl = this.apiUrl+ 'showBlogNewsBySlug';

  

  constructor(
    private http: HttpClient,
  ) { }



  // tag data from db
  getTagData(): Observable<any> {

     return this.http.get(this.tagsUrl);

   }


   //blog news from db
   getBlogNews(): Observable<any> {

    return this.http.get(this.blogsEventsUrl);

  } 

  getBlogNewsPaginate(number): Observable<any> {

    return this.http.get(this.blogsEventsPaginateUrl+number);

  }

   //blog news from db single item
  getBlogNewsItem(slug): Observable<any> {

    return this.http.get(this.blogsEventsItemsUrl + '/' + slug);

  }




}
