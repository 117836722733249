import { Component, Directive, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import {NgForm} from '@angular/forms';

import { environment } from '../../../environments/environment';

import { ProgramService } from './../services/program.service';
import { Programs, SpecialNeeds, RacialCategories } from './../model/programs';

import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDatepicker } from '@angular/material';
import { MomentDateModule, MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';

import { MatStepper } from '@angular/material';

import { DatePipe } from '@angular/common';

import { NgxSpinnerService } from "ngx-spinner";
import { ContactService } from '../services/contact.service';
import { PageHeaderService } from '../services/page-header.service';
import { FlashnotificationService } from 'src/app/admin/services/flashnotification.service';

import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';

import { Title, Meta  } from '@angular/platform-browser';





declare var paypal;

interface LevelEducation {
  id: number;
  level: string;
  major_track: string
  program_start_date: string;
  program_end_date: string;
  degree_title: string;
  university_name: string;
}

export const DateFormats = {
  parse: {
      dateInput: ['YYYY-MM-DD']
  },
  display: {
      dateInput: 'YYYY-MM-DD',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY/MM',
  },
  display: {
    dateInput: 'YYYY/MM',
    monthYearLabel: 'YYYY MMM',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY MMMM',
  },
};

 


@Directive({
  selector: '[dateFormat1]',
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})

export class CustomDateFormat1 {

 

}

@Component({
  selector: 'app-application-form',
  templateUrl: './application-form.component.html',
  styleUrls: ['./application-form.component.scss'],
  providers: [

    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
      { provide: MAT_DATE_FORMATS, useValue: DateFormats }

  ],

  
})




export class ApplicationFormComponent implements OnInit {
  //base Url
  apiUrl = environment.apiUrl;


  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;

  countries: any;
  countriesRes: any;
  countriesIndex;
  states;
  states2;

  programs: any;
  programsRes: any;
  subPrograms: Programs[];
  subPrograms2: Programs[];
  Racial_categories: RacialCategories[];
  Racial_categoriesRes;
  Special_needs: SpecialNeeds[];
  Special_needsRes;
  essayDescription: any[];
  essayDescriptionRes: any;
  dataPrivacy: any;

  programApplyingIndex = 0;
  allSubPrograms;
  allSubProgramsFiltered;

  executed: boolean = false;
  
  loader: boolean = false;

  completed: boolean;

  successPaymentMessage:boolean = false;

  discountedPrice: number;
  showDiscount: any;
  discount: number;
  validBtnStatus: boolean;
  couponRes: any;
  couponValue: any;
  couponMessage: any;
  couponMessageRes: any;

  // image base url
  imageUrl = environment.uploadFilesUrl;
  

  // upload file data step 7
  filedataDiploma: any;
  fileEventGrade_transcript: any;
  fileEventEnglish_test_cert: any = '';
  fileEventResume: any;
  fileEventCopy_of_passport: any;
  fileEventOther_documents: any = '';



  public error:any = null;
  // api urls
  applicationFormApiUrl = this.apiUrl+'applicationInformations';
  applicationFormApiUrlFile = this.apiUrl+'applicationInformations/upload';
  couponApiUrl = this.apiUrl+'applicationInformations/cupon_validate';
  

  // applied for apiUrl
  

  // PREVIOUS EDUCATION  ARRAY
  public educations: any[] = [{
    level: '',
    major_track: '',
    program_start_date: '',
    program_end_date: '',
    degree_title: '',
    university_name: '',
    gpa: ''
  }];

  
  // EMPLOYMENT HISTORY
  public employmentHistories: any[] = [{ 
    company_name: '',
    type_of_industry: '',
    job_title: '',
    start_date: '',
    end_date: ''
   }];


   
  //  public program_of_interest: any = {
  //   applied_for: null,
  //   program_track_applied: null,
  //   planned_term_of_entry: null,
  //   is_re_applicant: null,
  //   enrolled_pecb_university: null,
  //   currently_enrolled: null,
  // },
   
  

  //Step 1
  public form = {
    title: null,
    gender: null,
    first_name: null,
    middle_name: null,
    last_name: null,
    date_of_birth: null,
    nationality:null,
    citizenship: null,
    passport_number: null,
    country_id: null,
    home_address_street_address: null,
    home_address_city: null,
    state_id: null,
    home_address_zip_code: null,
    contact_country_id: null,
    contact_state_id: null,
    contact_address_street_address: null,
    contact_address_city: null,
    contact_address_state: null,
    contact_address_zip_code: null,
    email: null,
    mobile_phone: null,
    home_phone: null,

    program_of_interest: {
      applied_for: null,
      program_track_applied: null,
      planned_term_of_entry: null,
      is_re_applicant: null,
      enrolled_pecb_university: null,
      currently_enrolled: null,
    },

    student_conduct: {
      have_you_ever_been_in_discipline_probation_on_university: null,
      discipline_reason: null,
      sponsored_by_organization: null,
      guilty_reason: null,
      have_you_ever_been_guilty_of_a_crime: null,
    },
    
    previous_education: [] = this.educations,

    employment_history: this.employmentHistories,
    

    additional_informations: {
      native_english: null,
      main_language: null,
      proficiency_test_taken: null,

      t_write: null,
      t_read: null,
      t_speak: null, 
      t_listen: null,
      t_overall: null,

      i_write: null,
      i_read: null,
      i_speak: null, 
      i_listen: null,
      i_overall: null,

      sponsored_by_organization: null,
      if_yes_org_name: null,
      desired_industry: null,
      desired_function: null,
    },

    
    supporting_details: {
      racial_categories: null,
      hispanic_latino: null,
      special_needs: null,
      specify: null,
    },
    

    personal_statement: {
      essay: null,
    },
    

    // platform: null,
    // specify_platform: null,

    hear_about_us: {
      p_website: null,
      p_insights: null,
      p_email: null,
      p_events: null,
      p_social: null,
      c_student: null,
      alumni: null,
      p_other: null,
      p_specify: null, 
    },
    

    supporting_documents: {
      diploma: null, 
      grade_transcript: null,
      english_test_cert: null,
      resume: null, 
      copy_of_passport: null, 
      other_documents: null,
    },
   

    references: {
      r1_title:null,
      r1_first_name: null,

      r1_last_name: null,
      r1_qualifications: null, 
      r1_phone: null,
      r1_email: null,

      r2_title: null,
      r2_first_name: null,
      r2_last_name: null,
      r2_qualifications: null, 
      r2_phone: null,
      r2_email: null,
    },
     

     statements: {
      all_infos_complete: null,
      verify_my_app_and_essay:  null,
      permission_to_verify_info: null,
      disiplinary_action: null,
      property_of_pecb: null,
      fee_is_nonrefundable: null,
     }, 
     data_privacy: {
      checked: null,
      text: 1, 
    },

    

     payment: {
      payment_type: null,
      cupon_code: null,
      new_value: null
     },
     orderId: null,
    
  }


  english_test: any;

  //site key
  siteKey = environment.siteKey;

  Captcha;



  //header image
  pageHeaderImage;

  // price from backend
  priceUrl = this.apiUrl + 'applicationFee';
  priceItem;
  priceRes;

  // aplication form fee
  price = 150;

  // Paypal step 8
  paidFor = false;
  paypalShow = true;

  

  public payPalConfig?: IPayPalConfig;

  product = {
    price: null,
    description: 'Pecb University Application form',
    
  };

  minDate: Date;
  maxDate: Date;
  currentYear: any;

  @ViewChild('paypal', { static: false }) paypalElement: ElementRef;

  constructor(
    private _formBuilder: FormBuilder,
    private programService: ProgramService,
    private http: HttpClient,
    public datepipe: DatePipe,
    private changeDetectorRef: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private pageHeaderService: PageHeaderService,
    private flashNotification: FlashnotificationService,
    private titleService: Title,
    private meta: Meta,

    
    
    ) { }

  ngOnInit() {
    this.currentYear = new Date().getFullYear(),
    
    // this.minDate = new Date(this.currentYear - 20, 0, 1),
    this.maxDate = new Date(this.currentYear - 19, 0, 0),
    // this.maxDate = new Date(this.currentYear + 1, 0, 0),

    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });

    
    this.initConfig();
  

    this.getPrograms();
    this.getSubPrograms();
  
    this.getRacialCategories();
    this.getSpecialNeeds();
    this.getEssayDescription();
    this.getDataPrivacy();  
    this.getCountries();
    this.paypalToggle();
    this.getPriceData();
    this.getPageHeader();

    

    // console.log(this.AllsubPrograms);
   // console.log(this.fileEventOther_documents);
   this.titleService.setTitle('PECB University - Application Form')
      this.meta.addTags([
        {name: 'keywords', content: 'PECB University-Application Form'},
      {name: 'description', content: 'PECB University is committed to providing an experience that spawns its students’ intellectual horizon, and helping them to acquire various primed skills for successful adaptation in the business world. Your journey as a PECB University student allows you to explore multiple prominent disciplines, and prepares you to become a highly qualified professional in the chosen industry. We will continue the grit and determination to provide you with superior education while teaching relevant subjects that aid you in becoming critical, independent, and daring professionals'},
        ]);
    
  }


  //Main Form Submit
  submitForm(){
    this.submitMainForm();
  }
  
  //Submit form without files
  submitMainForm( ) {  
    this.spinner.show();
    this.loader = true;
    //date birth formating
    let date_of_birth = this.formatDate(this.form.date_of_birth);
    this.form.date_of_birth = date_of_birth; 

    var myFormData = new FormData();
    const headers = new HttpHeaders();

    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    this.http.post(this.applicationFormApiUrl, this.form, {
      headers: headers
    }).subscribe(res => {
        res => this.form;
       // console.log('done');
       this.loader = false;
       
       this.submitMainFormFile();
      setTimeout(() => {
      this.successPaymentMessage = true; 
      /** spinner ends after  seconds */
      this.spinner.hide();
    }, 2000);
       
         
      //  console.log(this.form); 
    }, err => {
       // console.log('Error occured');
       this.error = err;
       this.spinner.show();
       if(this.error.status == 200) {
        
        this.submitMainFormFile();
        this.loader = false;
        setTimeout(() => {
        this.successPaymentMessage = true; 
        this.spinner.hide();
      }, 2000);
        this.loader = false;

        return false
      }
      this.flashNotification.showNotification('Something went wrong, please try again later.', 'danger');
      this.spinner.hide();
      this.loader = false;

        
         console.log(this.error.message);
    });
  }

  // submit files from form
  submitMainFormFile() {  
    
    var myFormData = new FormData();
    const headers = new HttpHeaders();
   // this.form.supporting_documents.diploma =  this.filedataDiploma;
      
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    
    myFormData.append('diploma', this.filedataDiploma);
    myFormData.append('grade_transcript', this.fileEventGrade_transcript);
    myFormData.append('english_test_cert', this.fileEventEnglish_test_cert);
    myFormData.append('resume', this.fileEventResume);
    myFormData.append('copy_of_passport', this.fileEventCopy_of_passport);
    myFormData.append('other_documents', this.fileEventOther_documents);
    
  
    this.http.post(this.applicationFormApiUrlFile, myFormData, {
      headers: headers
    }).subscribe(res => {
        res => this.form;
        //console.log('done');
    }, err => {
       // console.log('Error occured');
         this.error = err;
         this.loader = false;
         console.log(this.error.message);
         this.spinner.hide();

         
    });

  }


  // firstStepForm(){
  //     firstStepForm: NgForm
  //     firstStepForm.resetForm();
  // }

  //Add file events 1, step 7
  fileEventDiploma(e){
    this.filedataDiploma = e.target.files[0];
    //console.log(this.filedataDiploma);
  }

  //Add file events 2, step 7
  fileEventgrade_transcript(e){
    this.fileEventGrade_transcript = e.target.files[0];
    //console.log(this.fileEventGrade_transcript.name);
  }

  //Add file events 3, step 7
  fileEventenglish_test_cert(e){
    this.fileEventEnglish_test_cert = e.target.files[0];
    //console.log(this.fileEventEnglish_test_cert.name);
  }


  //Add file events 4, step 7
  fileEventresume(e){
    this.fileEventResume = e.target.files[0];
    //console.log(this.fileEventResume.name);
  }

  //Add file events 5, step 7
  fileEventcopy_of_passport(e){
    this.fileEventCopy_of_passport = e.target.files[0];
   // console.log(this.fileEventCopy_of_passport.name);
  }

  //Add file events 6, step 7
  fileEventother_documents(e){
    this.fileEventOther_documents = e.target.files[0];
    //console.log(this.fileEventOther_documents.name);
  }

  // Format Date
  formatDate(date){

    let value = this.datepipe.transform(date, 'yyyy-MM-dd');
    return value;
  }

  

  // Get programs applied for 1
  getPrograms(): void {
    this.programService.getPrograms().subscribe(
      res => {
         this.programsRes = res;
         this.programs = this.programsRes.data;
      }
   )
  }

  //Get program track applied for
  getSubPrograms(): void {
    this.programService.getSubPrograms().subscribe(
      res => {
         this.subPrograms = res
        // console.log(this.subPrograms)
      }
   )
  }


  //get price from db
  getPriceData(){
    return this.http.get<any>(this.priceUrl).subscribe(res => {
        this.priceRes = res;
        this.priceItem = this.priceRes.data[0];  
       // console.log(this.priceItem.value);
        this.product.price = this.priceItem.value;
          
    },
    err => {
      console.log('Error occured');
      
      console.log(err);
    });
  }

  //get price from db
  

  // //get Subprograms 2
  // getSubPrograms2(): void {
  //   this.programService.getSubPrograms2().subscribe(
  //     subPrograms2 => this.subPrograms2 = subPrograms2
  //  )
  // }

  // get racial categories om step 4
  getRacialCategories(): void {
    this.programService.getRacialCategories().subscribe( res => {
      this.Racial_categoriesRes = res;
      this.Racial_categories = this.Racial_categoriesRes.data;
    }
   )
  }

  // get special needs om step 4
  getSpecialNeeds(): void {
    this.programService.getSpecialNeeds().subscribe( res => {
       this.Special_needsRes = res
       this.Special_needs = this.Special_needsRes.data
      //console.log(this.Special_needs);
    }
      // Special_needs => this.Special_needs = Special_needs
   )
  }

  // easy description on step 5
  getEssayDescription(): void {
    this.programService.getEssayDescription().subscribe(
       res => { 
        this.essayDescriptionRes = res;
        this.essayDescription = this.essayDescriptionRes.data;
        
       } 
     
    )
  }

  // get data provacy description on step 7
  getDataPrivacy(): void {
    this.programService.getDataPrivacy().subscribe(
      dataPrivacy => this.dataPrivacy = dataPrivacy
    )
  }

  // filter subprograms, exclude subprograms with status 0
   checkStatus(subprograms) {
      return subprograms.status > 0;
  }

  // Display subprograms after choose Program
  getProgramsIndex(i) {
    //console.log(i);
    this.programApplyingIndex = i;
    
    this.allSubPrograms = this.programs[i].program_track;


    console.log(this.allSubPrograms)

    this.allSubProgramsFiltered = this.allSubPrograms.filter(function (el)
    {
      return el.status >=1 
    }
    );

    console.log(this.allSubProgramsFiltered);
    
    
  }
 

  // Get countries states 
  getCountriesIndex(i) {
    this.countriesIndex = i;
    
    this.states = this.countries[i].states;
    //console.log(this.states);
  }

  // Get countries states on contact address
  getCountriesIndex2(i) {
    this.states2 = this.countries[i].states;
    //console.log(this.states);
  }


//Get Countries
 getCountries(): void {
   this.programService.getCountriesApi().subscribe(
   res => {
     this.countriesRes =res;
     this.countries = this.countriesRes.data;
     //console.log(this.countries);
   }
  
   )
 }

  // add Another PREVIOUS EDUCATION
  addEducation() {
    this.educations.push({
      level: '',
      major_track: '',
      program_start_date: '',
      program_end_date: '',
      degree_title: '',
      university_name: '',
      gpa: ''
    });
  }

  // remove  PREVIOUS EDUCATION
  removeEducation(i: number){
    this.educations.splice(i, 1);
  }

   // add Another EMPLOYMENT HISTORY
   addEmploymentHistory() {
    this.employmentHistories.push({
      company_name: '',
      type_of_industry: '',
      job_title: '',
      start_date: '',
      end_date: ''
    });
  }

  // remove EMPLOYMENT HISTORY
  removeEmploymentHistory(i: number) {
    this.employmentHistories.splice(i, 1);
  }

  // Stepper Back function
  goBack(stepper: MatStepper) {
    stepper.previous();
  }

  // customTrackBy(index: number, obj: any): any {
  //   return index;
  // }

  // Paypal  show 
  paypalToggle() {
      this.paypalShow = true;

      this.changeDetectorRef.detectChanges();
      
      // if(!this.executed) {

      //   this.executed = true;
      //   paypal
      //   .Buttons({
      //     createOrder: (data, actions) => {
      //       return actions.order.create({
      //         purchase_units: [
      //           {
      //             description: this.product.description,
      //             amount: {
      //               currency_code: 'USD',
      //               value: this.product.price
      //             }
      //           }
      //         ]
      //       });
      //     },
      //     onApprove: async (data, actions) => {
      //       const order = await actions.order.capture();
      //       this.paidFor = true;
      //      // console.log(data);
            
      //       this.form.payment.payment_type = 'Paypal';
      //      // console.log(order);
      //     },
      //     onError: err => {
      //       //console.log(err);
      //     }
      //   })
      //   .render(this.paypalElement.nativeElement);
      // }

     



  }


  private initConfig(): void {
    this.payPalConfig = {
    currency: 'USD',
    clientId: 'AcpBGKhqj2_qXAo6o4AYwfhpAHk8HAhcw5dxF0pYW3tESdie7sfnbbnPrNvKsSPLtPZ2XJ_sROwq60cw',
    createOrderOnClient: (data) => <ICreateOrderRequest>{
      intent: 'CAPTURE',
      purchase_units: [
        {
          amount: {
            currency_code: 'USD',
            value: this.product.price,
            breakdown: {
              item_total: {
                currency_code: 'USD',
                value: this.product.price
              }
            }
          },
          items: [
            {
              name: this.product.description,
              quantity: '1',
              category: 'DIGITAL_GOODS',
              unit_amount: {
                currency_code: 'USD',
                value: this.product.price,
              },
            }
          ]
        }
      ]
    },
    advanced: {
      commit: 'true'
    },
    style: {
      label: 'paypal',
      layout: 'vertical'
    },
    onApprove: (data, actions) => {
      //console.log('onApprove - transaction was approved, but not authorized', data, actions);
      actions.order.get().then(details => {
       // console.log('onApprove - you can get full order details inside onApprove: ', details);
        this.paidFor = true;
        this.form.payment.payment_type = 'Paypal';
        this.form.orderId = details.id;
      });
    },
    onClientAuthorization: (data) => {
     // console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
      this.paidFor = true;
      this.form.payment.payment_type = 'Paypal';
    },
    onCancel: (data, actions) => {
      //.log('OnCancel', data, actions);
    },
    onError: err => {
      console.log('OnError', err);
    },
    onClick: (data, actions) => {
      //console.log('onClick', data, actions);
    },
  };
}

  // Paypal hide
  paypalBillme() {
    this.paypalShow = false;
    this.executed = false;
  }


// validate coupon code
onValidateCoupon() {
  
  var myFormData = new FormData();
  const headers = new HttpHeaders();
 // this.form.supporting_documents.diploma =  this.filedataDiploma;
    
  headers.append('Content-Type', 'multipart/form-data');
  headers.append('Accept', 'application/json');
  
  myFormData.append('cupon_code', this.form.payment.cupon_code);

  this.http.post(this.couponApiUrl, myFormData, {
    headers
  }).subscribe(res => {
      let productPrice = this.product.price;

      this.couponRes = res;
      
      this.couponValue = this.couponRes.value;
    
      this.couponMessage = this.couponRes.message;

      this.discount = productPrice * this.couponValue / 100;


      productPrice = productPrice - this.discount;

      this.discountedPrice =  productPrice;

      this.product.price = this.discountedPrice;

      this.showDiscount = true;

      this.form.payment.new_value = this.discountedPrice;

     // console.log(this.discountedPrice);
      
      if(this.discountedPrice == 0) {
        this.form.payment.payment_type = 'free'
        this.paypalShow = false;
      }

      // console.log(this.form.payment.payment_type);
      // console.log(this.paypalShow);
      
      switch (this.couponMessage) {
        case 'Cupon is not valid!':
              this.couponMessageRes = false;

              this.paypalShow = true;
              this.product.price = this.priceItem.value
                          
        break;
        case 'Cupon is validated!':
          this.couponMessageRes = true;
        break; 
      } 

  }, err => {
     // console.log('Error occured');
       this.error = err;
  
       console.log(this.error.message);
  });

}

 // get page header
 getPageHeader(): void {
  this.pageHeaderService.getImageHeader('applicationForm').subscribe(
    res => {
      this.pageHeaderImage = res;
     // console.log(this.pageHeaderImage.image);
      

    } 
 )
}

allPrograms:any = [
  {
    title: 'MBA Programs',
    subPrograms: ['Business Continuity', 'Information Security Management', 'Information Technology Service Management', 'Quality Management', 'Risk Management']
  },
  {
    title: 'Graduate Certificates',
    subPrograms: ['Business Administration', 'Management Systems Administration', 'Business Continuity Management', 'Information Security Management', 'IT Service Management' ]
  }
]
   
     
  



}





