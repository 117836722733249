import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/switchMap';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  //base url
  apiUrl = environment.apiUrl;

  // search api
  searchUrl = this.apiUrl+'search';
  error;

  constructor(
    private http: HttpClient
    ) { }


    searchItems(searchTerm) {

    
      var myFormData = new FormData();
      const headers = new HttpHeaders();
    
      // headers.append('Content-Type', 'multipart/form-data');
      // headers.append('Accept', 'application/json');
    
    
      // myFormData.append('search', searchTerm);
    
     return this.http.post(this.searchUrl, searchTerm, {
       headers
     });
    }


}
