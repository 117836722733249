import { Component, OnInit, ViewChild } from '@angular/core';


import { NavbarService } from '../../services/navbar.service';


import { SocialMediaIcons, Menu, MainMenu, LogoPath } from '../../model/navbar';
import { environment } from 'src/environments/environment';




@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})



export class NavbarComponent implements OnInit {

  searchKeyWord;

  //seleted menu variable to diplay dropdown submenu
  selectedItem;

  imageUrl = environment.uploadFilesUrl;



  socialMediaIcons: SocialMediaIcons[];
  socialMediaIconsRes: any;
  firstTopMenu: MainMenu[];
  firstTopMenuRes: any;
  mainMenu: Menu[];

  navbarLogo: LogoPath;
  navbarLogoPath:any;
  navbarLogoRes: any;

  navbarLogoURl = this.imageUrl;

  openMobileMenu: boolean = false;
  


  constructor(
    private navbarService: NavbarService
  ) { }

  ngOnInit() {
    this.callNavbarApi();
  
    
  }

  //calling all navbar api at once
  callNavbarApi(){
      this.navbarService.requestDataFromMultipleSources().subscribe(res => {

        //Main menu res from service
        this.mainMenu = res[0];

        //First Top  from service
        this.firstTopMenuRes = res[1];
        this.firstTopMenu = this.firstTopMenuRes.data;
        //console.log( this.firstTopMenu);
        

        //Social Media  from service
        this.socialMediaIconsRes = res[2];;
        this.socialMediaIcons = this.socialMediaIconsRes.data;

        //Main logo path from logo
        this.navbarLogoRes = res[3];
        this.navbarLogo = this.navbarLogoRes.data[0];
        this.navbarLogoPath = this.navbarLogoURl + this.navbarLogo.logo_img;
    });
  }

   //toogle subemnus
   toggleMenu(i) {
    
    if( this.selectedItem == i) {
        this.selectedItem = '';
        return;
    }
    this.selectedItem = i;
  }

//  getSocialMediaIcons(): void {
//     this.navbarService.getSocialMediaIcons().subscribe( res => {

//       this.socialMediaIconsRes = res;
//       this.socialMediaIcons = this.socialMediaIconsRes.data;
      
//     }
      
//    )
//  }

//  getFirstTopMenu(): void {
//     this.navbarService.getfirstTopMenuData().subscribe( res => {
//       this.firstTopMenuRes = res;
//       this.firstTopMenu = this.firstTopMenuRes.data;
//     }
//  )
// }

  // getMainMenu(): void {
  //   this.navbarService.getMainMenuData().subscribe(
  //     mainMenu => this.mainMenu = mainMenu
  //   )
  // }

  // getLogoPath(): void {
  //   this.navbarService.getLogoPathData().subscribe(
  //     navbarLogo => this.navbarLogo = navbarLogo
  //   )
  // }

  // getLogoPath(): void {
  //   this.navbarService.getLogoPathData().subscribe( res => {
  //     this.navbarLogoRes = res;
  //     this.navbarLogo = this.navbarLogoRes.data[0];

  //     this.navbarLogoPath = this.navbarLogoURl + this.navbarLogo.logo_img;     
  //   }
  //   )
  // }

}

