import { Component, OnInit } from '@angular/core';
import { ContactService } from '../services/contact.service';

import { costumerSupport } from '../model/costumer';
import { environment } from 'src/environments/environment';
import { PageHeaderService } from '../services/page-header.service';
import { NgForm } from '@angular/forms';
import { FlashnotificationService } from 'src/app/admin/services/flashnotification.service';
import { Title, Meta  } from '@angular/platform-browser';
import { tileLayer, latLng, marker, icon } from 'leaflet';
import 'leaflet/dist/images/marker-shadow.png';
import 'leaflet/dist/images/marker-icon.png';


@Component({
  selector: 'app-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit {

  public form = {
    name: null,
    email: null,
    message: null,
    terms_and_conditions: null,
  }

  imageUrl = environment.uploadFilesUrl;

   siteKey = environment.siteKey;

  pageHeaderImage;


  loader: boolean = false;

  costumerSupportsRes: any;
  costumerSupports: costumerSupport[];

  subscribeForm;
  

  Captcha;

  recaptchaStr = '';

  options = {
    layers: [
        tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { minZoom: 10, maxZoom: 18,  attribution: 'PECB University Address: 1250 Connecticut Avenue, Suite 700, Washington, D.C' })
    ],
    zoom: 17,
    center: latLng(38.907014, -77.042205 )
};

layers = [
  marker([ 38.907014, -77.042205 ], {
    icon: icon({
       iconSize: [ 70, 70 ],
       iconAnchor: [ 13, 30 ],
       iconUrl: './assets/img/location.png',
    })},
  )];

  constructor(
    private contactservice: ContactService,
    private pageHeaderService: PageHeaderService,
    private flashNotification: FlashnotificationService,
    private titleService: Title,
    private meta: Meta,
  ) { }

  ngOnInit() {
   // this.getCostumerSupport();
    this.getPageHeader();
    this.titleService.setTitle('PECB University - Contact Us')
      this.meta.addTags([
        {name: 'keywords', content: 'PECB University-Contact Us'},
      {name: 'description', content: 'PECB University is committed to providing an experience that spawns its students’ intellectual horizon, and helping them to acquire various primed skills for successful adaptation in the business world. Your journey as a PECB University student allows you to explore multiple prominent disciplines, and prepares you to become a highly qualified professional in the chosen industry. We will continue the grit and determination to provide you with superior education while teaching relevant subjects that aid you in becoming critical, independent, and daring professionals'},
        ]);
  }

//   onSubmitClick(captchaRef: any): void {
//     if (this.recaptchaStr) {
//         captchaRef.reset();
//     }
//     captchaRef.execute();
// }

// public resolved(captchaResponse: string): void {
//   this.recaptchaStr = captchaResponse;
//   if (this.recaptchaStr) {
//       this.contactFormSubmit();
//   }
// }



  //SUBMIT FORM TO CONTACT SERVICE
  contactFormSubmit(contactForm: NgForm) {
    this.loader = true;
    this.contactservice.sendContactForm(this.form).subscribe(
      res => {
        console.log(res.message);
        contactForm.resetForm()
        this.loader = false;
        this.flashNotification.showNotification('Thank you for contacting us', 'success');
      }
    , err => {
      console.log(err);
      this.loader = false;
      this.flashNotification.showNotification('Something went wrong, please try again later.', 'danger');
      
    });
  }

  //CUSTUMER SERVICE DATA
  getCostumerSupport(): void {
    this.contactservice.getCostumerSupport().subscribe(
      res => {
        this.costumerSupportsRes = res;
        this.costumerSupports = this.costumerSupportsRes.data 
      } 
   )
  }

  // get page header
  getPageHeader(): void {
    this.pageHeaderService.getImageHeader('contactUs').subscribe(
      res => {
        this.pageHeaderImage = res;
        // console.log(this.pageHeaderImage.image);
        

      } 
   )
  }



}
