import { Injectable } from '@angular/core';

import { Resolve, Router } from '@angular/router';

import { ActivatedRouteSnapshot } from '@angular/router';
import { PagesService } from './pages.service';
import { catchError } from 'rxjs/operators';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';
import { Observable } from 'rxjs';
import { ProgramsService } from './programs.service';


@Injectable({
  providedIn: 'root'
})
export class SubprogramResolverService  implements  Resolve<any> {

  constructor(
    private programsService: ProgramsService,
    private router: Router
  ) { }

  
  resolve(route: ActivatedRouteSnapshot) {

    return this.programsService.getSubProgramItem(route.paramMap.get('subprogram'))
    .pipe(catchError(err => {
      this.router.navigate(["/not-found"]);
      return 'EMPTY';
    })); 
  }
}
