import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { StaffService } from 'src/app/web/services/staff.service';

@Component({
  selector: 'app-popup-staff',
  templateUrl: './popup-staff.component.html',
  styleUrls: ['./popup-staff.component.scss']
})
export class PopupStaffComponent implements OnInit {

  imageUrl = environment.uploadFilesUrl;
  staffItemRes;
  staffItem;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private staffService: StaffService,
  ) { }

  ngOnInit() {

    this.staffService.getStaffItemData(this.data.id).subscribe(res => {
      this.staffItemRes = res;
      this.staffItem = this.staffItemRes.data;
      
    })
  
  }

}
