import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';

import { subscribeSection, contactinformation, footerMenu, bottomFooterPecb, bottomFooterMenu } from '../model/footer';
import {  MainMenu, Menu } from '../model/navbar';

import {
  SUBSCRIBEDATA,
  CONTACTINFORMATIONS,
  BOTTOMFOOTERMENUS,
  BOTTOMFOOTERPCB,

 } from '../data-mock';

@Injectable({
  providedIn: 'root'
})
export class FooterService {

  apiUrl = environment.apiUrl;

  mainMenuQuickUrl = this.apiUrl+'showFullMenu';
  contactInfoUrl = this.apiUrl+'universityInfo';
  footerBottomLeftData = this.apiUrl+'copyright';

  newslettersContentUrl = this.apiUrl+'newsletter_section';

  footerMenuUrl = this.apiUrl+'showFullFooterMenu';

  footerBottomMenu = this.apiUrl+'menu';






  constructor(
    private http: HttpClient,

  ) { }

  //FOOTER TOP
  getSubscriptionData(): Observable<any> {
    //return of(SUBSCRIBEDATA);
    return this.http.get(this.newslettersContentUrl);
  }

  //FOOTER BODY
    //CONTACT INFORMATION
    // getContactInformationData(): Observable<contactinformation[]> {
    //   return of(CONTACTINFORMATIONS);
    // }

    getContactInformationData(): Observable<any> {
     // return of(CONTACTINFORMATIONS);
      return this.http.get(this.contactInfoUrl);
    }

    //FOOTER MAIN MENU
    // getFooterMainMenuData(): Observable<footerMenu[]> {
    //   return of(FOOTERMENUS);
    // }

    getFooterMenu(): Observable<any> {

      return this.http.get(this.footerMenuUrl);

    }

  //FOOTER BOTTOM
    //FOOTER BOTTOM MENU
    getFooterBottomMenuData(): Observable<Menu[]> {
      return this.http.get<Menu[]>(this.mainMenuQuickUrl);
    } 
    
    // FOOTER BOTTOM LEFT 
    // getFooterBottomLeftData(): Observable<bottomFooterPecb> {
    //   return of(BOTTOMFOOTERPCB);
    // } 

    getFooterBottomLeftData(): Observable<bottomFooterPecb> {
      // return of(BOTTOMFOOTERPCB);
      return this.http.get<bottomFooterPecb>(this.footerBottomLeftData);
    } 

    //footer bottom main menu
    getFooterBottomMenu() {
      return this.http.get<Menu[]>(this.footerBottomMenu);
    }

  
}
