import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';


import { FooterService } from '../../services/footer.service';

import { subscribeSection, contactinformation, footerMenu, bottomFooterPecb, bottomFooterMenu } from '../../model/footer';
import { NavbarService } from '../../services/navbar.service';
import {  MainMenu, Menu, LogoPath } from '../../model/navbar';
import { environment } from 'src/environments/environment';
import { NgForm } from '@angular/forms';
import { FlashnotificationService } from 'src/app/admin/services/flashnotification.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  apiUrl = environment.apiUrl;  
  imageUrl = environment.uploadFilesUrl;

subscribeDatas: subscribeSection;
subscribeDataIcon;
subscribeDataText;
subscribeDataPlaceholder;
subscribeDataButton_content;
subscribeDataRes;

contactinformations: any;
contactinformation:any;
contactinformationRes: any;

footerMenusRes;
footerMenus: footerMenu[];
bottomFooterMenusRes;
bottomFooterMenus: Menu[];

footerLogo: LogoPath;
footerLogoPath:any;
footerLogoRes: any;

footerLogoURl = this.imageUrl;

subscribeEmailUrl = this.apiUrl+'newslettersEmail';

bottomFooterPecb: bottomFooterPecb;

bottomFooterPecbRes: any;

public form = {
  email: null,
  captcha: null,
}

public error:any = null;

siteKey = environment.siteKey;

recaptchaStr = '';
Captcha;

subscribeEmailRes:any;

  constructor(
    private footerService: FooterService,
    private navbarService: NavbarService,
    private http: HttpClient,
    private flashNotification: FlashnotificationService,
  ) { }

  ngOnInit() {
    this.getSubscription();
    this.getContactInformation();
    this.getFooterMainMenu();

   // setTimeout(()=> this.getFooterBottomMenu(), 6000);
    

    this.getFooterBottomLeft();
    this.getLogoPath();
  //  this.loadChat('../../../assets/liveChat.js');
    
  }

  //TOP FOOTER
  getSubscription(): void {
    this.footerService.getSubscriptionData().subscribe( res => {
      this.subscribeDataRes = res;
      
      this.subscribeDataIcon = this.subscribeDataRes.data[0].icon;
      this.subscribeDataText = this.subscribeDataRes.data[0].text;
      this.subscribeDataPlaceholder = this.subscribeDataRes.data[0].placeholder;
      this.subscribeDataButton_content = this.subscribeDataRes.data[0].button_content;
      
    }
      // subscribeData => this.subscribeData = subscribeData
   )
  }

 
  
  onSubmitClick(captchaRef: any): void {
    
    if (this.recaptchaStr) {
        captchaRef.reset();
    }
    captchaRef.execute();
}

public resolved(captchaResponse: string) {
  
  const newResponse = captchaResponse
      this.subscriptionEmail();

}

//  resolved(captchaResponse: string) {
//   this.recaptchaStr = captchaResponse;
//   console.log( this.form.email + ' aa');
//   if (this.recaptchaStr) {

   
//     var myFormData = new FormData();
//     const headers = new HttpHeaders();
//     headers.append('Content-Type', 'multipart/form-data');
//     headers.append('Accept', 'application/json');
  
//     myFormData.append('email', this.form.email);
    
//     this.http.post(this.subscribeEmailUrl,  myFormData, {
//       headers: headers
//     }).subscribe(res => {
//       res => this.form;
      
//   }, err => {
//       console.log('Error occured');
      
//        this.error = err;
//        console.log(this.error.message);
//   });
//   }
// }

subscriptionEmail() {
  var myFormData = new FormData();
  const headers = new HttpHeaders();
  headers.append('Content-Type', 'multipart/form-data');
  headers.append('Accept', 'application/json');

  myFormData.append('email', this.form.email);
  
  this.http.post(this.subscribeEmailUrl,  myFormData, {
    headers: headers
  }).subscribe(res => {
    this.subscribeEmailRes = res

    this.form.email = '';
    this.flashNotification.showNotification(this.subscribeEmailRes.message, 'success');
     
}, err => {
    console.log('Error occured');
    console.log( this.form.email);
     this.error = err;
     console.log(this.error.message);
});

}


  // subscriptionEmail() {
  //   this.http.post(this.subscribeEmailUrl, this.form).subscribe(res => {
  //     res => this.form;
      
  // }, err => {
  //     console.log('Error occured');
  //      this.error = err;
  //      console.log(this.error.message);
  // });
  // }
  //FOOTER BODY
    //CONTACT INFORMATION
  // getContactInformation(): void {
  //   this.footerService.getContactInformationData().subscribe(
  //     contactinformations => this.contactinformations = contactinformations
  //  )
  // }

  getContactInformation(): void {
    this.footerService.getContactInformationData().subscribe( res => {
      this.contactinformationRes = res;
      this.contactinformations = this.contactinformationRes.data;
    }
      
   )
  }
  
  //FOOTER MAIN MENU
  getFooterMainMenu(): void {
    this.footerService.getFooterMenu().subscribe(
      footerMenus => {
      this.footerMenus = footerMenus
      //console.log(this.footerMenus);
      }
      
   )
  }

  //FOOTER BOTTOM MENU
  getFooterBottomMenu(): void {
    this.footerService.getFooterBottomMenu().subscribe( res => {
        this.bottomFooterMenusRes = res
        this.bottomFooterMenus =this.bottomFooterMenusRes.data 
    }
      
   )
  }





  //FOOTER BOTTOM LEFT
  getFooterBottomLeft(): void {
    this.footerService.getFooterBottomLeftData().subscribe( res => {
      this.bottomFooterPecbRes = res;
      this.bottomFooterPecb = this.bottomFooterPecbRes.data[0].content;
    }
      // bottomFooterPecb => this.bottomFooterPecb = bottomFooterPecb
   )
  }


  getLogoPath(): void {
    this.navbarService.getLogoPathData().subscribe( res => {
      this.footerLogoRes = res;
      this.footerLogo = this.footerLogoRes.data[0];

      this.footerLogoPath = this.footerLogoURl + this.footerLogo.logo_img;
      
    }
    )
  }

  // Livechat
  public loadChat(url: string) {
    const body = <HTMLDivElement> document.querySelector('.top-footer');
    
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }



}
