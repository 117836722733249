import { Component, OnInit } from '@angular/core';

import { HomeService } from '../../../services/home.service';
import { offeringSnapshot } from '../../../model/offering-snapshot';

import { ProgramsService } from '../../../services/programs.service';

import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-offering-snapshots',
  templateUrl: './offering-snapshots.component.html',
  styleUrls: ['./offering-snapshots.component.scss']
})
export class OfferingSnapshotsComponent implements OnInit {
   
  //  SNAPSHOT OF OFFERING WIDGETS SECTION DATA
  offeringPrograms: offeringSnapshot[];

  //slug from url
  slug: any;

  
  // image base url
  imageBaseUrl = environment.uploadFilesUrl;


  //pages Item main res
  programsRes: any;
  //pages Item data
  programs: any;

  
  constructor(
    private homeService: HomeService,
    private programsService: ProgramsService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {
    setTimeout(()=> this.getProgram(), 1500);
  }


  // get program from service
  getProgram() {
    this.programsService.getPrograms().subscribe(res => {
      this.programs  = res;
     // console.log(this.programs);
      
     
     // console.log(this.programs);   
    })
  }

//   getOfferingSnapshots(): void {
//     this.homeService.getOfferingSnapshotData().subscribe(
//       offeringPrograms => this.offeringPrograms = offeringPrograms
//    )
//  }

}
