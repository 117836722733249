import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class FaqsService {

  apiUrl = environment.apiUrl;

  faqs: any;
  faqRes: any;
  faq: any;

  faqUrl = this.apiUrl+'faq';
  faqCategoriesUrl = this.apiUrl+'faqCategory';

  constructor(
    private http: HttpClient
  ) { }

  getFaqs(): Observable<any> {
     return this.http.get(this.faqUrl);
   }

   getFaqsCategories(): Observable<any> {
    return this.http.get(this.faqCategoriesUrl);
  }
}
