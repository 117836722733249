import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { environment } from '../../../environments/environment';


import {programRecords,programContents,Academics } from '../model/program';

import {
  PROGRAMRECORDS,
  TREE_DATA,
 } from '../data-mock';

@Injectable({
  providedIn: 'root'
})
export class ProgramsService {

   //Base api url
   apiUrl = environment.apiUrl;

   // Pages api url
   programItemUrl = this.apiUrl + 'showFullPrograms'

   subProgramItem = this.apiUrl + 'showProgramItemBySlug';

     // Sidebar menu urll
  sidebarMenuUrl = this.apiUrl+ 'showFullSideMenu'

  constructor(
    private http: HttpClient
  ) { }


 getProgramTableDate(): Observable<programRecords[]> {
  return of(PROGRAMRECORDS);
} 

// get all program items
getPrograms(): Observable<any> {
  return this.http.get<any>(this.programItemUrl);
}


// get program items from db
getProgramItem(slug): Observable<any> {
  return this.http.get<any>(this.programItemUrl + '/' + slug);
}

// get subprogram item from db
getSubProgramItem(slug): Observable<any> {
  return this.http.get<any>(this.subProgramItem + '/' + slug);
}

// side bar menu on pages
getSidebarMenu(): Observable<any> {
  return this.http.get(this.sidebarMenuUrl);
}




  
}
