import { Component } from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';

import { NgxSpinnerService } from "ngx-spinner";
import { Location } from '@angular/common';

import { environment } from '../environments/environment';

declare let ga: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'university';
  location: Location;

 

  constructor(
    private spinner: NgxSpinnerService,
    public router: Router
    ) {
      
    }


  ngOnInit() {
    this.router.events.subscribe(event => {

      if (event instanceof NavigationEnd) {
        ga('set', 'page', event.urlAfterRedirects);
        ga('send', 'pageview');

      }

    });
    /** spinner starts on init */
    this.spinner.show();
 
   
    if (environment.production) {
      if (location.protocol === 'http:') {
        window.location.href = location.href.replace('http', 'https');
        setTimeout(() => {
          /** spinner ends after 2.5 seconds */
          this.spinner.hide();
        }, 1700);
      } else {
        setTimeout(() => {
          /** spinner ends after 2.5 seconds */
          this.spinner.hide();
        }, 1700);
      }
    } else {
      setTimeout(() => {
        /** spinner ends after 2.5 seconds */
        this.spinner.hide();
      }, 1700);
    }

   
  }


}
