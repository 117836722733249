import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS  } from '@angular/common/http';

import { AdminRoutingModule } from './admin.routing';
import { ComponentsModule } from './components/components.module';

import { AdminComponent } from './admin.component';


import { LoginComponent } from './login/login.component';


import {
  AgmCoreModule
} from '@agm/core';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { RegisterComponent } from './register/register.component';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';
import {MatDialogModule} from '@angular/material';
import {
  MatButtonModule,
  MatInputModule,
  MatRippleModule,
  MatFormFieldModule,
  MatTooltipModule,
  MatSelectModule,
  
} from '@angular/material';
import {MatTableModule} from '@angular/material/table';
import { CommonModule } from '@angular/common';


import { JwtInterceptor } from './services/jwt.interceptor';






// import { SearchPipe } from './pipes/search.pipe';





@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    //HttpModule,
    MatDialogModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatRippleModule,
    MatTooltipModule,
    MatSelectModule,
    MatTableModule,
    HttpClientModule,
    ComponentsModule,
    RouterModule,
    AdminRoutingModule,
    AgmCoreModule.forRoot({
      apiKey: 'YOUR_GOOGLE_MAPS_API_KEY'
    }),
    
  
  ],
  entryComponents: [DeleteDialogComponent],
  declarations: [
    AdminComponent,
    AdminLayoutComponent,
    LoginComponent,
    RegisterComponent,
    DeleteDialogComponent,



    ],
  providers: [
    //{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }
    
  ],

})
export class AdminModule { }
