import { Injectable } from '@angular/core';


import { Resolve, Router } from '@angular/router';

import { ActivatedRouteSnapshot } from '@angular/router';
import { PagesService } from './pages.service';
import { catchError } from 'rxjs/operators';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PageResolverService implements  Resolve<any> {

  constructor(
    private pageService: PagesService,
    private router: Router
  ) { }

  resolve(route: ActivatedRouteSnapshot) {
    // return this.pageService.getPageItem(route.paramMap.get('id')).catch(() => {
    //   console.log('error');
    //   this.router.navigateByUrl('/home');
    // });

    return this.pageService.getPageItem(route.paramMap.get('slug'))
    .pipe(catchError(err => {
      this.router.navigate(["/not-found"]);
      return 'EMPTY';
    })); 
  }
}
