import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { welcomeSection, welcomeIcons } from '../model/welcome-section';
import { HomeWidgets } from '../model/home-widgets';
import { offeringSnapshot } from '../model/offering-snapshot';
import { topStoriesHeader, topStories } from '../model/topstories';

import {
  //  WELCOMESECTION,
   //WELCOMEICONS,
   //WIDGETSHOME,
   OFFERINGSNAPSHOT,
   TOPSTORIESDATA,
   TOPSTORIESHEADERDATA,

  } from '../data-mock';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(
    private http: HttpClient
  ) { }

  //Base api url
  apiUrl = environment.apiUrl;

//  baseUrl = 'http://127.0.0.1:8000/' 
 welcomeSectionIconsUrl = this.apiUrl+'programElement';

 applyWidgetsUrl = this.apiUrl+'applyWidget';

 welcomeSectionUrl = this.apiUrl+'welcomeSection';

  // WELCOME SECTION DATA
  getWelcomeSectionData(): Observable<any> {
    // return of(WELCOMESECTION);
    return this.http.get<any>(this.welcomeSectionUrl);
  }

  // getWelcomeSectionDataIcons(): Observable<welcomeIcons[]> {
  //   return of(WELCOMEICONS);
  // }

  getWelcomeSectionDataIcons(): Observable<welcomeIcons[]> {
    return this.http.get<welcomeIcons[]>(this.welcomeSectionIconsUrl);
  }

  // WIDGETS ON HOME SECTION DATA
  // getWidgetsSectionData(): Observable<HomeWidgets[]> {
  //   return of(WIDGETSHOME);
  // } 

  getWidgetsSectionData(): Observable<HomeWidgets[]> {
    return this.http.get<HomeWidgets[]>(this.applyWidgetsUrl);
  } 

  //OFFERING SNAPSHOT DATA
  getOfferingSnapshotData(): Observable<offeringSnapshot[]> {
   return of(OFFERINGSNAPSHOT)
  }

  // TOP STORIES DATA
  getTopStoriesHeaderData(): Observable<topStoriesHeader[]> {
    return of(TOPSTORIESHEADERDATA)
   } 

  getTopStoriesData(): Observable<topStories[]> {
    return of(TOPSTORIESDATA)
   } 

}
