import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { Slider } from '../model/slider';

 import { SLIDERCONTENTS } from '../data-mock';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})



export class SliderService {

  apiUrl = environment.apiUrl;

  sliderContentDataUrl = this.apiUrl+'slider';


  constructor(
    private http: HttpClient
  ) { }

  getSliderContentData(): Observable<Slider[]> {
   //return of(SLIDERCONTENTS);
     return this.http.get<Slider[]>(this.sliderContentDataUrl);
  }
}
