import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { TokenService } from '../services/token.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

   public form = {
    name: null,
    email: null,
    password: null,
    password_confirmation: null, 
  }

  public error = [];

  constructor(
   private authService: AuthService,
   private tokenService: TokenService,
   private router: Router,
  ) { }

  onSubmitRegister() {
    console.log(this.form);
     this.authService.register(this.form).subscribe(
      // data => console.log(data),
      data => this.handleResponse(data),
      error => this.handleError(error)
      // error => console.log(error)
    );
  }


  handleResponse(data) {
    this.tokenService.handle(data.token);
    this.router.navigateByUrl('/dashboard/home');
  }
 
  handleError(error:any) {
    this.error = error.error.errors;
  }


  ngOnInit() {
  }

}
