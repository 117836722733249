import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { ActivatedRoute } from '@angular/router';
import 'rxjs/add/operator/filter';
import { SearchService } from '../services/search.service';
import { HttpHeaders } from '@angular/common/http';
import { PageHeaderService } from '../services/page-header.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  results: Object;
  searchTerm$ = new Subject<string>();

  searchedResultRes;

  searchedResult;

  faqs:any[] = [];

  pages:any[] = [];

  searchKeyword;

  imageUrl = environment.uploadFilesUrl;

  pageHeaderImage;


  public form = {
    search: null,
    }

  constructor(
    private searchService: SearchService,
    private route: ActivatedRoute,
    private pageHeaderService: PageHeaderService,
    
  ) { }

  ngOnInit() {

      //taking slug from url
      this.route.queryParams
      .filter(params => params.searchKeyword)
      .subscribe(params => {
       // console.log(params); 

        this.searchKeyword = params.searchKeyword;

        this.onSearch();
        
      });

      this.getPageHeader();


  
    
  }

  onSearch() {

   this.faqs = [];

    this.pages = [];

    
    this.getSearchWord();
    this.searchService.searchItems(this.form).subscribe(res => {
      //console.log(res);
      this.searchedResultRes = res;
      this.searchedResult =  this.searchedResultRes.data;
      ///console.log(this.searchedResult);

      //seperating results from main res to specific type PAge
      for(let res of this.searchedResult) {
        if(res.type == 'page') {
      
          this.pages.push(res)
          //console.log(this.pages);
          
        }
      }

      //seperating results from main res to specific type Faq
      for(let res of this.searchedResult) {
        if(res.type == 'faq') {
          
          this.faqs.push(res)
          //console.log(this.faqs);
        }
      }
      
    })
  }

  getSearchWord() {
    this.form.search = this.searchKeyword ;
    //console.log(this.searchKeyword );
   // console.log(this.form);
  }

   // clear search input, showw all inputs back
   clearSearch() {
    this.searchKeyword = '';
    this.form.search = '';
    this.faqs = [];

    this.pages = [];
  }

  
  // get page header
  getPageHeader(): void {
    this.pageHeaderService.getImageHeader('search').subscribe(
      res => {
        this.pageHeaderImage = res;
        // console.log(this.pageHeaderImage.image);
        

      } 
   )
  }

}
