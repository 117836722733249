import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { TokenService } from '../services/token.service';
import { Router } from '@angular/router';
import { ThrowStmt } from '@angular/compiler';
import { ProtectAuthService } from '../services/protect-auth.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

 
  public form = {
    email: null,
    password: null
  }

  loader: boolean = false;

  public error = null;

  constructor(
   private authService: AuthService,
   private tokenService: TokenService,
   private router: Router,
   private protectAuthService: ProtectAuthService,
   
    ) {

   }

  

  onSubmitLogin() {
    this.error = null;
    this.loader = true
    this.authService.login(this.form).subscribe(
      data => {
        this.handleResponse(data)
        this.loader = false
      },
      error => { 
        this.loader = false
        this.handleError(error)
      } 
      // error => console.log(error)
    );
  }

  handleResponse(data) {
    this.tokenService.handle(data.access_token);
    
    this.protectAuthService.changeAuthStatus(true);
    this.router.navigateByUrl('/dashboard/home');
  }

 

  handleError(error:any) {
    this.error = error.error.error;
  }


  ngOnInit() {
  }

}
