import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./components/home-components/home/home.component";
import { ProgramComponent } from "./components/program-components/program/program.component";
import { NewsEventsComponent } from "./components/news-events/news-events.component";
import { SubmenusPageComponent } from "./components/submenus/submenus-page/submenus-page.component";
import { TextPagesComponent } from "./components/submenus/text-pages/text-pages.component";
import { SubmenuStaffComponent } from "./components/submenus/submenu-staff/submenu-staff.component";
import { ExpressInterestComponent } from "./components/forms/express-interest/express-interest.component";
import { MainComponent } from "./main/main.component";
import { ContactPageComponent } from "./contact-page/contact-page.component";
import { ApplicationFormComponent } from "./application-form/application-form.component";
import { PageResolverService } from "../web/services/page-resolver.service";
import { NotFoundComponent } from "./not-found/not-found.component";
import { FaqsComponent } from "./faqs/faqs.component";
import { ProgramResolverService } from "./services/program-resolver.service";
import { ProgramItemsComponent } from "./program-items/program-items.component";
import { SubprogramResolverService } from "./services/subprogram-resolver.service";
import { SearchComponent } from "./search/search.component";
import { NewsEventsItemsComponent } from "./news-events-items/news-events-items.component";
import { BlogsNewsResolverService } from "./services/blogs-news-resolver.service";
import { SholarshipFormComponent } from "./sholarship-form/sholarship-form.component";
import { LeadershipCommitteesComponent } from "./components/submenus/leadership-committees/leadership-committees.component";

const routes: Routes = [
  { path: "", redirectTo: "home", pathMatch: "full" },
  {
    path: "",
    component: MainComponent,
    children: [
      { path: "", component: HomeComponent },
      { path: "home", component: HomeComponent },
      // {path: 'program', component: ProgramComponent},
      { path: "news-events", component: NewsEventsComponent },
      {
        path: "news-events/:slug",
        component: NewsEventsItemsComponent,
        resolve: { pageData: BlogsNewsResolverService },
      },
      // {path: 'programs',component: SubmenusPageComponent},
      {
        path: "programs/:slug",
        component: SubmenusPageComponent,
        resolve: { pageData: ProgramResolverService },
      },
      {
        path: "programs/:program/:subprogram",
        component: ProgramItemsComponent,
        resolve: { pageData: SubprogramResolverService },
      },
      {
        path: "page/:slug",
        component: TextPagesComponent,
        resolve: { pageData: PageResolverService },
      },
      { path: "staff/:name", component: SubmenuStaffComponent },
      // {path: 'express-interest', component: ExpressInterestComponent},
      { path: "contact", component: ContactPageComponent },
      { path: "application-form", component: ApplicationFormComponent },
      { path: "faqs", component: FaqsComponent },
      { path: "search", component: SearchComponent },
      { path: "scholarship-form", component: SholarshipFormComponent },

      {
        path: "leadership-and-committees",
        component: LeadershipCommitteesComponent,
      },
      { path: "**", component: NotFoundComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class WebRoutingModule {}
