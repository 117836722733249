import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';


import { STAFF} from '../data-mock';
import { Staff, StaffItem } from '../model/staff';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StaffService {

  apiUrl = environment.apiUrl;

  staffItemUrl = this.apiUrl+'staff';

  staffCategoriesWithStaff = this.apiUrl+'showFullStaffType';


  constructor(
    private http: HttpClient,
  ) { }

  //STAFF PROFILE 
  getStaffData(): Observable<any> {
    //return of(STAFF);
    return this.http.get(this.staffItemUrl);
    
  }

  //STAFF PROFILE 
  getStaffItemData(id): Observable<any> {
    //return of(STAFF);
    return this.http.get(this.staffItemUrl+'/+'+id);
    
  }

  getCategoriesStaffData(): Observable<any> {
    //return of(STAFF);
    return this.http.get(this.staffCategoriesWithStaff);
    
  }



  // getStaffData(): Observable<Staff> {
  //   return of(STAFF);
  // }

}
