import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA,MatDialogRef} from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

import { DatePipe } from '@angular/common';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import { NgxSpinnerService } from "ngx-spinner";
import { FlashnotificationService } from 'src/app/admin/services/flashnotification.service';
import {Router} from '@angular/router';




@Component({
  selector: 'app-submit-form-popup',
  templateUrl: './submit-form-popup.component.html',
  styleUrls: ['./submit-form-popup.component.scss']
})
export class SubmitFormPopupComponent implements OnInit {

  //main api url
  apiUrl = environment.apiUrl;

  imageUrl = environment.uploadFilesUrl;

   //country
   countries: any;
   states;
 
 
   // programs
   programs: any;


   // student status
   studentStatusOne : any = 'Current student (enrolled in at least 4 courses in the upcoming semester)';

   studentStatusTwo : any = 'Prospective student (seeking to enroll in the upcoming semester)';

  //  student mode 
  programStudyModeOne : any = 'Full time (4 courses / 12 credits per semester)';

  programStudyModeTwo : any = 'Part time (2 courses / 6 credits per semester, or as agreed)';

// form array
  form: any[];

 // scholar files upload url api
  applScholarFormApiUrl = this.apiUrl+'scholarshipApplication';

  // scholar files upload url api
  applScholarFormApiUrlFile = this.apiUrl+'scholarshipApplication/upload';

  error: any;
  loader: boolean = false;
  success: boolean = false;
  upload_id: any;
  scholarShipFormRes: any;
  
  

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SubmitFormPopupComponent>,
    private http: HttpClient,
    public datepipe: DatePipe,
    private router: Router,
    private spinner: NgxSpinnerService,
    private flashNotification: FlashnotificationService,
  ) { 
    
  }


  ngOnInit() {
     this.form = this.data.form;

  }



  //Submit form without files
  submitMainForm( ) {  
   this.spinner.show();
    this.loader = true;


     //date birth formating
     let date_of_birth = this.formatDate(this.data.form.date_of_birth);
     this.data.form.date_of_birth = date_of_birth; 

     let date_of_application = this.formatDate(this.data.form.date_of_application);
     this.data.form.date_of_application = date_of_application;  

     if(this.data.form.english_lang_skill_id.native_speaker) {
      this.data.form.english_lang_skill_id.native_speaker = 1;
     } else {
      this.data.form.english_lang_skill_id.native_speaker = 0;
     }


     if(this.data.form.english_lang_skill_id.previous_education) {
      this.data.form.english_lang_skill_id.previous_education = 1;
     } else {
      this.data.form.english_lang_skill_id.previous_education = 0;
     }

     let fullName = this.data.form.first_name+' '+this.data.form.last_name;

    var myFormData = new FormData();
    const headers = new HttpHeaders();


    myFormData.append('student_id', this.data.form.student_id);

    myFormData.append('prefered_title', this.data.form.prefered_title);
    myFormData.append('last_name', this.data.form.last_name);
    myFormData.append('first_name', this.data.form.first_name);
    myFormData.append('date_of_birth', this.data.form.date_of_birth);
    myFormData.append('citizen_status', this.data.form.i_am_a);
    myFormData.append('country_id', this.data.form.country_id);
    myFormData.append('street_name_no', this.data.form.street_name_no);

    myFormData.append('apt', this.data.form.apt);
    myFormData.append('city', this.data.form.city);
     
    if( this.data.form.state) {
      myFormData.append('state_id', this.data.form.state);
    } else {
      myFormData.append('state_id', '');
    }
    myFormData.append('postal_code', this.data.form.postal_code);
    myFormData.append('mobile_phone', this.data.form.mobile_phone);
    myFormData.append('home_phone', this.data.form.home_phone);
    myFormData.append('email_address', this.data.form.email_address);
    myFormData.append('program_id', this.data.form.program_id);
    myFormData.append('student_status', this.data.form.student_status);
    myFormData.append('program_study_mode', this.data.form.program_study_mode);
    myFormData.append('highest_education_level', this.data.form.highest_education_level);
    myFormData.append('full_degree_diploma_certificate', this.data.form.full_degree_diploma_certificate);
    myFormData.append('gpa_local', this.data.form.gpa_local);
    myFormData.append('gpa_us', this.data.form.gpa_us);
    myFormData.append('years_of_working_in_field_of_study', this.data.form.years_of_working_in_field_of_study);
    myFormData.append('current_recent_position_held', this.data.form.current_recent_position_held);
    myFormData.append('personal_statement', this.data.form.personal_statement);
    myFormData.append('full_name', fullName);
    myFormData.append('signature', this.data.form.signature);
    myFormData.append('date_of_application', this.data.form.date_of_application);
    //myFormData.append('personal_statement_student_id', this.data.form.student_id);

    myFormData.append('native_speaker', this.data.form.english_lang_skill_id.native_speaker);
    myFormData.append('completed_previous_education', this.data.form.english_lang_skill_id.previous_education);
    myFormData.append('toefl', this.data.form.english_lang_skill_id.toefl);
    myFormData.append('ielts', this.data.form.english_lang_skill_id.ielts);
    myFormData.append('other_eng_skill', this.data.form.english_lang_skill_id.other);
    

    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    this.http.post(this.applScholarFormApiUrl, myFormData, {
      headers: headers
    }).subscribe(res => {
       // res => this.form;
      // console.log(res);
    
       this.scholarShipFormRes =res;
       this.upload_id = this.scholarShipFormRes.id;
      this.submitMainFormFile(this.upload_id);

    
      
    }, err => {
       // console.log('Error occured');
       this.error = err;
       console.log(this.error) 
       this.spinner.hide();
       this.loader = false;
         console.log(this.error.message);

         this.flashNotification.showNotification('Something went wrong, please try again.', 'danger');
    });
  }




  // submit files from form
  submitMainFormFile(id) {  
    
    var myFormData = new FormData();
    const headers = new HttpHeaders();
 
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    
     myFormData.append('previous_degrees_diplomas_certificates', this.data.attachments.fileDataDiploma);
     myFormData.append('transcripts_previous_degrees_diplomas_certificates', this.data.attachments.filedataPreviousDegrees);
     myFormData.append('english_language_skills_proof', this.data.attachments.filedataEnglishSkillsProof);
      myFormData.append('reference_1', this.data.attachments.filedataReferenceLetterOne);
      myFormData.append('reference_2', this.data.attachments.filedataReferenceLetterTwo);

     

     if( this.data.attachments.filedataGmatGreResults) {
      myFormData.append('gmat_gre_results', this.data.attachments.filedataGmatGreResults);
    } else {
      myFormData.append('gmat_gre_results', '');
    }



     if( this.data.attachments.filedataRelevantProffesionalCert) {
      myFormData.append('relevant_professional_certificates', this.data.attachments.filedataRelevantProffesionalCert);
    } else {
      myFormData.append('relevant_professional_certificates', '');
    }
  
       
    if( this.data.attachments.filedataOther) {
      myFormData.append('other_documents', this.data.attachments.filedataOther);
    } else {
      myFormData.append('other_documents', '');
    }

    
    this.http.post(this.applScholarFormApiUrlFile+'/'+id, myFormData, {
      headers: headers
    }).subscribe(res => {
        res => this.form;

        this.loader = false;
        this.success = true;
        this.spinner.hide();
       //console.log(res)
       if(this.success) {
        this.flashNotification.showNotification('Thank you, your application has been successfully submitted', 'success');
        this.loader = true;
      
        this.dialogRef.close();
        this.router.navigate(['/home']);
       }
       // console.log('done'+  this.success );
    }, err => {
        console.log('Error occured');
         this.error = err;
        //  this.loader = false;
        console.log(this.error.message);
        console.log("Error on Files");
        //  this.spinner.hide();
        this.spinner.hide();
       this.loader = false;
        this.flashNotification.showNotification('Something went wrong, please try again.', 'danger');
         
    }); 

  }


   // Format Date
   formatDate(date){

    let value = this.datepipe.transform(date, 'yyyy-MM-dd');
    return value;
  }



  // print view
  onPrint() {
    document.title = this.data.form.first_name+'-'+this.data.form.last_name+'-EMBA-scholarship-appl';
    window.print();
  }




}
