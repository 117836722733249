import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { environment } from '../../../environments/environment';

import {Programs, SpecialNeeds, RacialCategories } from '../model/programs';

import {
  PROGRAMS, SUBPROGRAMMBA, SUBPROGRAMGRADUATE, SUBPROGRAMS, RACIAL_CATEGORIES, SPECIAL_NEEDS, ESSAYDESCRIPTION, DATAPRIVACY
 } from '../data-mock';

@Injectable({
  providedIn: 'root'
})
export class ProgramService {

  apiUrl = environment.apiUrl;

  // Countries url api
  countriesUrl = this.apiUrl+'countries/withStates';

  // Program Applied for url api
  appliedForProgram = this.apiUrl+'programApplied/withTracks';

  // Program track applied for api
  programTrackApplied = this.apiUrl+'programTrackApplied';

  // Special needs api
  specialNeeds = this.apiUrl+'specialNeeds';

  //Racial categories api
  racialCategories = this.apiUrl+'racialCategories';

  //Essay Description =
  essayDescription = this.apiUrl+'personalStatement';


  constructor(
    private http: HttpClient
  ) { }
  
  
  // Get applied programs from db
  getPrograms(): Observable<any> {
    return this.http.get<any>(this.appliedForProgram);
  } 

  // Get program track from db
  getSubPrograms(): Observable<any> {
    return this.http.get<any>(this.programTrackApplied);
  } 

  // getSubPrograms(): Observable<SubPrograms[]> {
  //   return of(SUBPROGRAMS);
  // } 

  getRacialCategories(): Observable<RacialCategories[]> {
    return this.http.get<RacialCategories[]>(this.racialCategories);
  } 
  


  getSpecialNeeds(): Observable<SpecialNeeds[]> {
    return this.http.get<SpecialNeeds[]>(this.specialNeeds);
  } 

  getEssayDescription(): Observable<any[]> {
    return this.http.get<any[]>(this.essayDescription);
 
  }


  getCountriesApi(): Observable<any> {
    return this.http.get<any>(this.countriesUrl);
  }

  


  getDataPrivacy(): Observable<any[]> {
    return of(DATAPRIVACY);
  }
  
  getSubPrograms2(): Observable<Programs[]> {
    return of(SUBPROGRAMGRADUATE);
  } 


  
}
