import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PageHeaderService {
  apiUrl = environment.apiUrl;

  pageHeaderUrl = this.apiUrl+'manageHeaders/';

  constructor(
    private http: HttpClient,
  ) { }

  // tag data from db
  getImageHeader(slug): Observable<any> {

    return this.http.get(this.pageHeaderUrl+slug);

  }

}
