// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false,
//   apiUrl: 'http://localhost:8000/api/',
//   uploadFilesUrl: 'http://localhost:8000/uploads/',
//   // siteKey: '6LeJUMEUAAAAAPVzz-XWQvKyoseIASTm0Tso2OZY',
//   siteKey: '6Lc7P9UUAAAAAJNTbsZHubgEKh9lY3E5lhk5rNYd'

// };

export const environment = {
  production: true,
  apiUrl: "https://university-api.pecb.com/api/",
  uploadFilesUrl: "https://university-api.pecb.com/uploads/",
  siteKey: "6Lc7P9UUAAAAAJNTbsZHubgEKh9lY3E5lhk5rNYd",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
