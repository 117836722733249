import { NgModule } from '@angular/core';
import { 
  MatButtonModule,
  MatDialogModule,
  MatMenuModule,
  MatToolbarModule,
  MatInputModule,
  MatGridListModule,
  MatBottomSheetModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatStepperModule,
  MatBadgeModule,
  MatIconModule,
  MatTabsModule,
  MatTableModule,
  MatTreeModule,
  MatSidenavModule,
  MatFormFieldModule,
  MatSelectModule,
  MatCheckboxModule,
  MatRadioModule,
  MatExpansionModule,
  MatDividerModule
  
   } from '@angular/material';


const MaterialComponents = [
 MatButtonModule,
 MatDialogModule,
 MatMenuModule,
 MatToolbarModule,
 MatInputModule,
 MatGridListModule,
 MatBottomSheetModule,
 MatDatepickerModule,
 MatNativeDateModule,
 MatStepperModule,
 MatBadgeModule,
 MatIconModule,
 MatTabsModule,
 MatTableModule,
 MatTreeModule,
 MatSidenavModule,
 MatFormFieldModule,
 MatSelectModule,
 MatCheckboxModule,
 MatRadioModule,
 MatExpansionModule,
 MatDividerModule
 

];

@NgModule({

  imports: [
    MaterialComponents,
  ],
  exports: [
    MaterialComponents,
  ],
})
export class MaterialModule { }
