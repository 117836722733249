import { Component, OnInit } from '@angular/core';
import { FaqsService } from '../services/faqs.service'
import { environment } from 'src/environments/environment';
import { PageHeaderService } from '../services/page-header.service';
import { Title, Meta  } from '@angular/platform-browser';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})
export class FaqsComponent implements OnInit {

  panelOpenState = false;

  faqsRes: any;
  faqs: any;
  faq: any;

  faqsCategoriesRes: any;
  faqCategories: any;
  faqCategory: any;
  faqCategoryId:any = 'all';

  searchText;
  searchKeyword;

  imageUrl = environment.uploadFilesUrl;

  pageHeaderImage;

  constructor(
    private faqsService: FaqsService,
    private pageHeaderService: PageHeaderService,
    private titleService: Title,
    private meta: Meta,
  ) { }

  ngOnInit() {
    this.getFaqs();
    this.getFaqCategories();
    this.getPageHeader();
    this.titleService.setTitle('PECB University - FAQs')
      this.meta.addTags([
        {name: 'keywords', content: 'PECB University - FAQs'},
      {name: 'description', content: 'PECB University is committed to providing an experience that spawns its students’ intellectual horizon, and helping them to acquire various primed skills for successful adaptation in the business world. Your journey as a PECB University student allows you to explore multiple prominent disciplines, and prepares you to become a highly qualified professional in the chosen industry. We will continue the grit and determination to provide you with superior education while teaching relevant subjects that aid you in becoming critical, independent, and daring professionals'},
        ]);
  }

    // Get faq items from db
  getFaqs() {
    this.faqsService.getFaqs().subscribe(res => {
      this.faqsRes = res;
      this.faqs = this.faqsRes.data;    
  });
  }

  // Get faq categories from db
  getFaqCategories() {
    this.faqsService.getFaqsCategories().subscribe(res => {
      this.faqsCategoriesRes = res;
      this.faqCategories = this.faqsCategoriesRes.data
    });
  }


  showAllCategories() {
    this.faqCategoryId = 'all';
    this.clearSearch();
  }

  onCategories(id) {
    this.faqCategoryId = id;
    // this.getFaqs();
    this.clearSearch();
  }


  // Filter faqs
  getSearchWord() {
    this.searchKeyword = this.searchText;
    //console.log(this.searchKeyword );
    
  }

  // clear search input, showw all inputs back
  clearSearch() {
    this.searchText = '';
    this.searchKeyword = '';
  }

  // get page header
  getPageHeader(): void {
    this.pageHeaderService.getImageHeader('faq').subscribe(
      res => {
        this.pageHeaderImage = res;
        // console.log(this.pageHeaderImage.image);
      } 
   )
  }




}
