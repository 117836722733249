import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WebRoutingModule } from './web-routing.module';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';

import { MainComponent } from './main/main.component';

import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { SliderComponent } from './components/home-components/slider/slider.component';
import { WelcomeSectionComponent } from './components/home-components/welcome-section/welcome-section.component';
import { WidgetsApplyingComponent } from './components/home-components/widgets-applying/widgets-applying.component';
import { OfferingSnapshotsComponent } from './components/home-components/offering-snapshots/offering-snapshots.component';
import { TopStoriesComponent } from './components/home-components/top-stories/top-stories.component';
import { HomeComponent } from './components/home-components/home/home.component';
import { ProgramComponent } from './components/program-components/program/program.component';
import { AcademicsTreeComponent } from './components/program-components/academics-tree/academics-tree.component';
import { NewsEventsComponent } from './components/news-events/news-events.component';
import { SubmenusPageComponent } from './components/submenus/submenus-page/submenus-page.component';
import { TextPagesComponent } from './components/submenus/text-pages/text-pages.component';
import { SubmenuStaffComponent } from './components/submenus/submenu-staff/submenu-staff.component';
import { PopupStaffComponent } from './components/submenus/popup-staff/popup-staff.component';
import { ExpressInterestComponent } from './components/forms/express-interest/express-interest.component';

import { MaterialModule } from '../material-module/material.module';
import { OwlModule } from 'ngx-owl-carousel';
import { FormsModule, NG_VALIDATORS } from '@angular/forms';
import { NgxPayPalModule } from 'ngx-paypal';
import { RecaptchaModule, RecaptchaFormsModule,  RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { ContactPageComponent } from './contact-page/contact-page.component';
import { ApplicationFormComponent, CustomDateFormat1 } from './application-form/application-form.component';
import { SafehtmlPipe } from '../admin/pipes/safehtml.pipe';
import { NotFoundComponent } from './not-found/not-found.component';
import { FaqsComponent } from './faqs/faqs.component';
import { SearchWebPipe } from './pipes/search-web.pipe';
import { LoaderWebComponent } from './loader-web/loader-web.component';
import { ProgramItemsComponent } from './program-items/program-items.component';
import { SearchComponent } from './search/search.component';
import { NewsEventsItemsComponent } from './news-events-items/news-events-items.component';
import {NgxPaginationModule} from 'ngx-pagination';

import { environment } from '../../environments/environment';
import { MinWordsDirective } from './services/min-words.directive';
import { SholarshipFormComponent, CustomDateFormat2 } from './sholarship-form/sholarship-form.component';
import { ScholarMaxWordsDirective } from './services/scholar-max-words.directive';
import { SubmitFormPopupComponent } from './submit-form-popup/submit-form-popup.component';
import { LeadershipCommitteesComponent } from './components/submenus/leadership-committees/leadership-committees.component';



@NgModule({
  declarations: [
    MainComponent,
    NavbarComponent,
    FooterComponent,
    SliderComponent,
    WelcomeSectionComponent,
    WidgetsApplyingComponent,
    OfferingSnapshotsComponent,
    TopStoriesComponent,
    HomeComponent,
    ProgramComponent,
    AcademicsTreeComponent,
    NewsEventsComponent,
    SubmenusPageComponent,
    TextPagesComponent,
    SubmenuStaffComponent,
    PopupStaffComponent,
    ExpressInterestComponent,
    ContactPageComponent,
    ApplicationFormComponent,
    CustomDateFormat1,
    CustomDateFormat2,
    SafehtmlPipe,
    NotFoundComponent,
    FaqsComponent,
    SearchWebPipe,
    LoaderWebComponent,
    ProgramItemsComponent,
    SearchComponent,
    NewsEventsItemsComponent,
    MinWordsDirective,
    SholarshipFormComponent,
    ScholarMaxWordsDirective,
    SubmitFormPopupComponent,
    LeadershipCommitteesComponent,
  ],
  imports: [
    CommonModule,
    WebRoutingModule,
    MaterialModule,
    OwlModule,
    FormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    NgxPayPalModule,
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyAGshTAvu79MB7x7HOhu73zLPCABvUGr3E'
    // }),
    LeafletModule,
    NgxPaginationModule,

  ],
  entryComponents: [
    PopupStaffComponent,
    SubmitFormPopupComponent
  ],
  providers: [{
    provide: RECAPTCHA_SETTINGS,
    useValue: {
      siteKey: environment.siteKey,
    } as RecaptchaSettings,
  },
  {
    provide: NG_VALIDATORS,
    useExisting: MinWordsDirective,
    multi: true
  },
  {
    provide: NG_VALIDATORS,
    useExisting: ScholarMaxWordsDirective,
    multi: true
  }
],
})
export class WebModule { }
