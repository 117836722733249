import { welcomeSection, welcomeIcons } from './model/welcome-section';
import { SocialMediaIcons, Menu, MainMenu,LogoPath } from './model/navbar';
import { Slider } from './model/slider';
import { HomeWidgets } from './model/home-widgets';
import { offeringSnapshot } from './model/offering-snapshot'; 
import { topStoriesHeader, topStories } from './model/topstories';
import { subscribeSection, contactinformation, footerMenu, bottomFooterPecb, bottomFooterMenu } from './model/footer';
import { Programs, SubPrograms } from './model/programs';
import {  programRecords, programContents, Academics} from './model/program';
import { Staff } from './model/staff';
import { costumerSupport } from './model/costumer';

// NAVBAR DATA 
// export const SOCIALMEDIAICON: SocialMediaIcons[] = [
//     {
//       iconClass: 'fab fa-facebook-f',
//       link: '#',
//     },
//     {
//       iconClass: 'fab fa-linkedin-in',
//       link: '#',
//     },
//     {
//       iconClass: 'fab fa-twitter',
//       link: '#',
//     },
//     {
//       iconClass: 'fab fa-pinterest',
//       link: '#',
//     },
//     {
//       iconClass: 'fab fa-instagram',
//       link: '#',
//     },
//     {
//       iconClass: 'fab fa-youtube',
//       link: '#',
//     },
//   ];
 
 
  export const FIRSTTOPMENU: MainMenu[] = [
    {
      text: 'Apply Now',
      link: '#',
      submenus: '#',
      state: false,
    },
    {
      text: 'Express Interest',
      link: '#',
      submenus: '#',
      state: false,
    },
    {
      text: 'FAQ',
      link: '#',
      submenus: '#',
      state: false,
    },
    {
      text: 'Contact Us',
      link: '#',
      submenus: '#',
      state: false,
    },
    {
      text: 'Login',
      link: '#',
      submenus: '#',
      state: false,
    },
  ];

  export const MAINMENU: MainMenu[] = [
    {
      text: 'Home',
      link: '/',
      submenus: '',
      state: false,
    },
    {
      text: 'About',
      link: '',
      state: true,
      submenus: {
        
        submenusItem: [
          {
            title: 'History and Background',
            link: '/submenus-text-page' 
          },
          {
            title: 'Mission, Vision and Values',
            link: '#'
          },
          {
            title: 'University Leadership',
            link: '/submenu-staff'
          },
          {
            title: 'Faculty and Administration',
            link: '/submenu-staff'
          },
          {
            title: 'Why choose PECB University?',
            link: '#'
          },
        ]
      }
    },
    {
      text: 'ACADEMICS',
      link: '#',
      state: false,
      submenus: {
        bigMenu: true,
        submenusItem: [
          {
            title: 'Mba Programs',
            link: '/program',
            submenusItems: [
              {
                title: 'Business Administration',
                link: ''
              },
              {
                title: 'Management Systems Administration',
                link: ''
              },
              {
                title: 'Business Continuity Management',
                link: '/program'
              },
              {
                title: 'Information Security Management',
                link: ''
              },
              {
                title: 'IT Service Management',
                link: ''
              },
        ]

          },
          {
            title: 'Graduate Certificates',
            link: 'submenus-page',
            submenusItems: [
              {
                title: 'Business Continuity',
                link: '/'
              },
              {
                title: 'Information Security Management',
                link: ''
              },
              {
                title: 'Information Technology Service Management',
                link: ''
              },
              {
                title: 'Quality Management',
                link: ''
              },
              {
                title: 'Risk Management',
                link: ''
              },
            ]
          },
        ]
      }
    },

    {
      text: 'ADMISSIONS',
      link: '#',
      state: true,
      submenus: {
        submenusItem: [ 
          {
            title: 'Admissions Process',
            link: '#'
          },
          {
            title: 'Tuition and Fees',
            link: '#'
          },
          {
            title: 'Express Interest',
            link: '#'
          },
          {
            title: 'Apply Now',
            link: '#'
          },
          {
            title: 'Transfer of Credits',
            link: '#'
          },
          {
            title: 'Admissions Office',
            link: '#'
          }
        
        ]
      }
      
    },
    {  
      text: 'STUDENT SERVICES',
      link: '#',
      state: true,
      submenus: {
        submenusItem: [ 
          {
            title:'Student Academic Services',
            link: '#'
          },
          {
            title:'Student Records Management',
            link: '#'
          },
          {
            title:'Student Counseling',
            link: '#'
          },
          {
            title:'Student Rights and Responsibilities',
            link: '#'
          },
          {
            title:'Disability Services',
            link: '#'
          }
        ]
      }
    },   
     {
      text: 'NEWS & EVENTS',
      link: '/news-events',
      submenus: '#',
      state: false,
    },
    {
      text: 'ONLINE RESOURCES',
      link: '#',
      state: true,
      submenus: {
        submenusItem: [ 
          {
            title:'How Online Learning Works',
            link: '#'
          },
          {
            title:'Benefits of Online Learning',
            link: '#'
          },
          {
            title:'SIS',
            link: '#'
          },
          {
            title:'LMS',
            link: '#'
          },
          {
            title:'Student Email Account',
            link: '#'
          },
        ]
      }
    }
  ]

  export const LOGOPATH: LogoPath = {
    logo_img: 'assets/img/logo.svg'
  }


  // TOP SLIDER CONTENT
  export const SLIDERCONTENTS: Slider[] = [
    {
      id: 1,
      title: "Your bright future is our mission",
      description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eaque, provident eum sint tempora nesciunt magni rem? ",
      image: "assets/img/header.jpg",
      button_content: 'Read More',
      link: '#'
    },
    {
      id: 2,
      title: "Your bright future is our mission 2",
      description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eaque, provident eum sint tempora nesciunt magni rem? 222",
      image: "assets/img/header.jpg",
      button_content: 'Download',
      link: '#'
    },
    {
      id: 3,
      title: "Your bright future is our mission 3",
      description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eaque, provident eum sint tempora nesciunt magni rem? 33",
      image: "assets/img/header.jpg",
      button_content: 'Read More',
      link: '#'
    },
    
  ]

// WELCOME SECTION DATA
//  export const WELCOMESECTION: welcomeSection = 
//     {
//       textWelcome: 'Welcome to',
//       universityName: 'PECB University',
//       subtitle: 'Awesome success with Student ',
//       description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim voluptas excepturi sint ducimus. Corrupti commodi ab nam? Sapiente, quos praesentium odio nesciunt facilis libero, alias natus obcaecati nam, provident doloremque consequatur accusamus. Non nulla quasi accusamus laudantium molestias perferendis officia, voluptates placeat qui earum quibusdam quis nam voluptas assumenda eligendi!',
//       link: '#'
//     };
 
    // WELCOME SECTION DATA ICONS
//     export const WELCOMEICONS: welcomeIcons[] = [
//     {
//       iconClass: 'fas fa-briefcase',
//       text: 'Advance Your Carer' 
//     },
//     {
//       iconClass: 'fas fa-user-tie',
//       text: 'Learn from the best' 
//     },
//     {
//       iconClass: 'fas fa-puzzle-piece',
//       text: 'Practice new Skills' 
//     },
//     {
//       iconClass: 'fas fa-network-wired',
//       text: 'Network' 
//     },
//     {
//       iconClass: 'far fa-clock',
//       text: 'Save Time' 
//     }, {
//       iconClass: 'far fa-money-bill-alt',
//       text: 'Save Money' 
//     } 
// ];   

//WIDGETS HOME SECTION DATA

// export const WIDGETSHOME: HomeWidgets[] = [
//   {
//     text: 'Apply now',
//     url: '#',
//     image: 'https://picsum.photos/300/200?image=1',
//     class: 'fas fa-file-signature'  
//   }, 
//   {
//     text: 'Express Interest',
//     url: '#',
//     image: 'https://picsum.photos/300/200?image=2',
//     class: 'fas fa-question'  
//   },
//   {
//     text: 'Request Information',
//     url: '#',
//     image: 'https://picsum.photos/300/200?image=3',
//     class: 'fas fa-info'  
//   }
// ]

//OFFERING SNAPSHOTS DATA
 export const OFFERINGSNAPSHOT: offeringSnapshot[] = 
[ 
  { 
    title: 'MBA Programs',
    link: '',
    offeringItem: {
      offeringDetails:[
      {
      title: 'MBA - Business Continuity Management',
      url: '#',
      image: 'https://picsum.photos/400/250?image=12',
      description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt voluptatum totam dolorum.' 
    }, 
    {
      title: 'MBA - Information Security Managment',
      url: '#',
      image: 'https://picsum.photos/400/250?image=16',
      description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt voluptatum totam dolorum.' 
    }, 
    {
      title: 'MBA - Information Technology Service Managment',
      url: '#',
      image: 'https://picsum.photos/400/300?image=17',
      description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt voluptatum totam dolorum.' 
    },
  ]
 }
  },
  { 
    title: 'Graduate Certificates',
    link: '/submenus-page',
    offeringItem: {
      offeringDetails:[
      {
        title: 'Graduate Certificate - Business Continuity',
        url: '#',
        image: 'https://picsum.photos/400/250?image=18',
        description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt voluptatum lorem totam dolorum. lorem1' 
    }, 
    {
      title: 'Graduate Certificate - Information Security Managment',
      url: '#',
      image: 'https://picsum.photos/400/250?image=19',
      description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt voluptatum ipmsum totam dolorum.' 
    }, 
    {
      title: 'Graduate Certificate - Information Technology Service Managment',
      url: '#',
      image: 'https://picsum.photos/400/250?image=20',
      description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt voluptatum elit totam dolorum.' 
    },
    {
      title: 'Graduate Certificate - Quality Management',
      url: '#',
      image: 'https://picsum.photos/400/250?image=120',
      description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt voluptatum elit totam dolorum.' 
    },
    {
      title: 'Graduate Certificate - Risk Management',
      url: '#',
      image: 'https://picsum.photos/400/250?image=122',
      description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt voluptatum elit totam dolorum.' 
    },
    {
      title: 'Graduate Certificate - Bussiness Administration',
      url: '#',
      image: 'https://picsum.photos/400/250?image=123',
      description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt voluptatum elit totam dolorum.' 
    },
    {
      title: 'Graduate Certificate - Managment Systems Administration',
      url: '#',
      image: 'https://picsum.photos/400/250?image=124',
      description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt voluptatum elit totam dolorum.' 
    },
  ]
 },
  },
  { 
    title: 'Graduate Diploma',
    link: '',
    offeringItem: {
      offeringDetails:[
      {
      title: 'Information Security Management',
      url: '#',
      image: 'https://picsum.photos/400/250?image=12',
      description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt voluptatum totam dolorum.' 
    }, 
  ]
 }
  },
  
];

//TOP STORIES DATA ON CAROUSEL
export const TOPSTORIESHEADERDATA: topStoriesHeader[] = [ 
  {
    subtitle: 'News & Events',
    title: 'Top stories about',
    name: 'PECB University',
  }
]

export const TOPSTORIESDATA: topStories[] = [
  {
    image: "https://picsum.photos/340/200?image=160",
    date: "September 24, 2019",
    title: "Lorem ipsum 1",
    description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus reiciendis sunt quae facere pariatur ab molestias, id doloremque reprehenderit dicta.Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus reiciendis sunt quae facere pariatur ab molestias, id doloremque reprehenderit dicta."
  },
  {
    image: "https://picsum.photos/340/200?image=161",
    date: "September 25, 2019",
    title: "Lorem ipsum 2",
    description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus reiciendis sunt quae facere pariatur ab molestias, id doloremque reprehenderit dicta.Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus reiciendis sunt quae facere pariatur ab molestias, id doloremque reprehenderit dicta."
  },{
    image: "https://picsum.photos/340/200?image=162",
    date: "September 26, 2019",
    title: "Lorem ipsum 3",
    description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus reiciendis sunt quae facere pariatur ab molestias, id doloremque reprehenderit dicta.Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus reiciendis sunt quae facere pariatur ab molestias, id doloremque reprehenderit dicta."
  },
  {
    image: "https://picsum.photos/340/200?image=163",
    date: "September 27, 2019",
    title: "Lorem ipsum 4",
    description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus reiciendis sunt quae facere pariatur ab molestias, id doloremque reprehenderit dicta.Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus reiciendis sunt quae facere pariatur ab molestias, id doloremque reprehenderit dicta."
  },
]


//UNIVERSITY STAFF
 export const STAFF: Staff[] =[
  {
    title: 'PECB University Steering Committee',
    description: '',
    staff: [
      {
        id: 1,
        name: 'Faton Aliu',
        img: 'assets/img/A.png',
        preparition: '( M.Sc., PhD(c))',
        positions: [
          {
            title:'President at PECB Univesity;',
          },
          {
            title:'Co-Founder, President and COO at PECb Inc',
          },
        ],
        description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum,  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum.  "
      },
      {
        id: 2,
        name: 'ERIC LACHAPELLE',
        img: 'assets/img/B.png',
        preparition: '( M.b.a , M.I.M)',
        positions: [
          {
            title:'Vice President at PECB Univesity;',
          },
          {
            title:'Co-Founder, President and COO at PECb Inc',
          },
        ], 
        description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum,  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum.  "
      },
      {
        id: 3,
        name: 'Lorem Loremi',
        img: '',
        preparition: '( M.b.a , Lorem)',
        positions: [
          {
            title:'Lorem Ipsum Ippsumi',
          }
        ],
        description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum,  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum.  "
      },
      {
        id: 4,
        name: 'Ipsum More',
        img: '',
        preparition: '(M.I.M)',
        positions: [
          {
            title:'Vice President at Lorem',
          },
        ],
        description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum,  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum.  "
      },
      {
        id: 5,
        name: 'Lorem Djames',
        img: '',
        preparition: '( M.b.a , M.I.M)',
        positions: [
          {
            title:'Lorem ipsum Lorem ipsum',
          },

        ],
        description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum,  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum.  "
      },
      {
        id: 6,
        name: 'ERIC Bledsoe',
        img: '',
        preparition: '( M.b.a)',
        positions: [
          {
            title:'Lorem ipsum Lorem',
          },
        ],
        description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum,  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum.  "
      },
      {
        id: 7,
        name: 'taco fall',
        img: '',
        preparition: '( M.b.a , M.I.M)',
        positions: [
          {
            title:'Lorem ipsum Lorem',
          },
          
        ],
        description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum,  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum.  "
      },
      {
        id: 8,
        name: 'Fitim Rama',
        img: 'assets/img/C.png',
        preparition: '(BSc)',
        positions: [
          {
            title:'Managing Director at PECB Inc.',
          },
          
        ],
        description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum,  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum.  "
      },
      
    ]
   },
   {
    title: 'Faculty',
    description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum.',
    staff: [
      {
        id: 9,
        name: 'Josh Smith',
        img: 'assets/img/D.png',
        preparition: '(PhD)',
        positions: [
          {
            title:'Business Administration',
          }
        ],
        description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum,  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum.  "
      },
      {
        id: 10,
        name: 'Josh Smith',
        img: 'assets/img/D.png',
        preparition: '(PhD)',
        positions: [
          {
            title:'Business Administration',
          }
        ], 
        description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum,  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum.  "
      },
      {
        id: 11,
        name: 'Josh Smith',
        img: 'assets/img/D.png',
        preparition: '',
        positions: [
          {
            title:'Business Administration',
          }
        ],
        description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum,  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum.  "
      },
      {
        id: 12,
        name: 'Ipsum More',
        img: 'assets/img/D.png',
        preparition: '(M.I.M)',
        positions: [
          {
            title:'Business Administration',
          },
        ],
        description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum,  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum.  "
      },
      {
        id: 13,
        name: 'Lorem Djames',
        img: 'assets/img/D.png',
        preparition: '( M.b.a , M.I.M)',
        positions: [
          {
            title:'Business Administration',
          },

        ],
        description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum,  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum.  "
      },
      {
        id: 14,
        name: 'ERIC Bledsoe',
        img: 'assets/img/D.png',
        preparition: '( M.b.a)',
        positions: [
          {
            title:'Business Administration',
          },
        ],
        description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum,  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum.  "
      },
      {
        id: 15,
        name: 'taco fall',
        img: 'assets/img/D.png',
        preparition: '( M.b.a , M.I.M)',
        positions: [
          {
            title:'Business Administration',
          },
          
        ],
        description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum,  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum.  "
      },
      {
        id: 16,
        name: 'Lorem Ipsum',
        img: 'assets/img/D.png',
        preparition: '(BSc)',
        positions: [
          {
            title:'Business Administration',
          },
          
        ],
        description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum,  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum.  "
      },
      {
        id: 17,
        name: 'Lorem Ipsum',
        img: 'assets/img/D.png',
        preparition: '(BSc)',
        positions: [
          {
            title:'Business Administration',
          },
          
        ],
        description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum,  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum.  "
      },
      {
        id: 18,
        name: 'Lorem Ipsum',
        img: 'assets/img/D.png',
        preparition: '(BSc)',
        positions: [
          {
            title:'Business Administration',
          },
          
        ],
        description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum,  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum.  "
      }
     ] 
    }, 
    {
      title: 'Administration',
      description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum. Sed quo illum voluptates dolorum. Sed quo illum voluptates dolorum.',
      staff: [
        {
          id: 20,
          name: 'Josh Smith',
          img: 'assets/img/D.png',
          preparition: '(PhD)',
          positions: [
            {
              title:'Business Administration',
            }
          ],
          description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum,  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum.  "
        },
        {
          id: 21,
          name: 'Josh Smith',
          img: 'assets/img/D.png',
          preparition: '(PhD)',
          positions: [
            {
              title:'Business Administration',
            }
          ], 
          description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum,  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum.  "
        },
        {
          id: 22,
          name: 'Josh Smith',
          img: 'assets/img/D.png',
          preparition: '',
          positions: [
            {
              title:'Business Administration',
            }
          ],
          description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum,  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum.  "
        },
        {
          id: 23,
          name: 'Ipsum More',
          img: 'assets/img/D.png',
          preparition: '(M.I.M)',
          positions: [
            {
              title:'Business Administration',
            },
          ],
          description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum,  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum.  "
        },
        {
          id: 24,
          name: 'Ipsum More 2',
          img: 'assets/img/D.png',
          preparition: '(M.I.M)',
          positions: [
            {
              title:'Business Administration',
            },
          ],
          description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum,  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum, Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo illum voluptates dolorum,Sed quo illum voluptates dolorum.  "
        },
        
       
       ] 
      },
 ] 
//FOOTER DATA
  //TOP FOOTER DATA
  export const SUBSCRIBEDATA: subscribeSection = {
    icon: 'far fa-envelope mr-1',
    text: 'Subscribe to our newsletter',
    button_content: 'Subscribe now',
    placeholder: 'Enter your Email',
    link: '#'
  };  

  //FOOTER BODY DATA
    //CONTACT INFORMATION
    export const CONTACTINFORMATIONS: contactinformation[] = [
      {
        logo: 'assets/img/logo.svg',
        email: {
          email: 'university@pecb.com',
          iconClass: 'fas fa-envelope mr-2'
        },
        phone: {
          nr: '+1-844-426-7322 ext.80',
          iconClass: 'fas fa-phone mr-2'
        },
        address:{
          street: "1250 Connecticut Avenue, Suite 700,",
          state: 'Washington, D.C',
          zipCode: '20036',
          iconClass: 'fas fa-map-marker-alt mr-2'
        }
      }
    ];

    export const COSTUMERSUPPORT: costumerSupport[] = [
      {
        mainTitle: 'Head of Costumer Services',
        image: 'assets/img/D.png',
        name: 'Roberta Barbara',
        position: 'President & Managers',
        preparation: 'Ph.D',
        email: 'admin@university.com',
        phone: '222-2229-6000'
      },
      {
        mainTitle: 'Head of Costumer Services ',
        image: 'assets/img/D.png',
        name: 'Roberta Barbara 2',
        position: 'President & Managers 2',
        preparation: 'Ph.D',
        email: 'admin2@university.com',
        phone: '222-2229-6000'
      }
    ]

    //MAIN FOOTER MENU
  //   export const FOOTERMENUS: footerMenu[] = [
  //     {
  //     title: 'ABOUT',
  //     bodyMenus:{ 
  //         menuTitles: [
  //           {
  //             title: 'History and Background',
  //             link: '/submenus-text-page'
  //           },
  //           { 
  //             title:'Mission, Vision and Values',
  //             link: '#'
  //           },
  //           { 
  //             title:'University Leadership',
  //             link: '/submenu-staff'
  //           },
  //           { 
  //             title:'Administration and Faculty',
  //             link: '/submenu-staff'
  //           },
  //           { 
  //             title:'Why choose PECB University',
  //             link: '#'
  //           },
  //           { 
  //             title:'Location',
  //             link: '#'
  //           }
  //          ]
  //         }
  //   },
  //   {
  //     title: 'ACADEMICS',
  //     bodyMenus:{ 
  //         menuTitles: [
  //           { 
  //             title:'MBA Programs',
  //             link: '#'
  //           },
  //           { 
  //             title:'Graduate Certificates',
  //             link: '#'
  //           },
  //           { 
  //             title:'University Catalog',
  //             link: '#'
  //           },
  //           { 
  //             title:'List of Courses',
  //             link: '#'
  //           },
  //           { 
  //             title:'Library',
  //             link: '#'
  //           },
  //           { 
  //             title:'Academic Calendar',
  //             link: '#'
  //           },
  //           { 
  //             title:'Academic affairs',
  //             link: '#'
  //           },
  //           { 
  //             title:'Online Learning',
  //             link: '#'
  //           }
  //           ]
  //         }
  //   },
  //   {
  //     title: 'ADMISSIONS',
  //     bodyMenus:{ 
  //         menuTitles: [
  //           { 
  //             title:'Admissions Process',
  //             link: '#'
  //           },
  //           { 
  //             title:'Tuition and Fees',
  //             link: '#'
  //           },
  //           { 
  //             title:'Express Interest',
  //             link: '#'
  //           },
  //           { 
  //             title:'Apply Now',
  //             link: '#'
  //           },
  //           { 
  //             title:'Check Application Status',
  //             link: '#'
  //           },
  //           { 
  //             title:'Transfer of Credits',
  //             link: '#'
  //           },
  //           { 
  //             title:'Contact Admissions',
  //             link: '#'
  //           }
  //           ]
  //         }
  //   },
  //   {
  //     title: 'ONLINE RESOURCES',
  //     bodyMenus:{ 
  //         menuTitles: [
  //           { 
  //             title:'SIS',
  //             link: '#'
  //           },
  //           { 
  //             title:'LMS',
  //             link: '#'
  //           },
  //           { 
  //             title:'Student Email Account',
  //             link: '#'
  //           },
  //           { 
  //             title:'Benefits of Online Learning',
  //             link: '#'
  //           },
  //           { 
  //             title:'How Online Learning Works',
  //             link: '#'
  //           }
  //           ]
  //         }
  //   }
  // ];

  //PROGRAMS ON APPLICATION FORM
  export const PROGRAMS: Programs[] = [
    {
      title: 'MBA Programs',
    },
    {
      title: 'Graduate Certificates',
    }
  ]

  export const  RACIAL_CATEGORIES: Programs[] = [
    {
      title: 'American Indian or Alaska Native'
    },
    {
    title: 'Other'
   }
]


export const SPECIAL_NEEDS: Programs[] = [
  {
    title: 'I do not have a disability nor do I need additional support requirments during studies'
  },
  {
    title: 'I have dylexsia'
  },
  {
    title: 'I am blind/partially sighted'
  },
  {
    title: 'I am deaf/have a hearing impairment'
  },
  {
    title: 'I am a wheelchair user or have mobile difficulties'
  },
  {
    title: 'I need personal care support'
  },
  {
    title: 'I have mental health difficulties'
  },
  {
    title: 'I have an unseen disability, e.g diabets, epilepsy, asthma'
  },
  {
    title: 'I have two or more of the above disabilities/special needs'
  },
  {
    title: 'I have a disability not listed above'
  }

]
 

  export const SUBPROGRAMS: SubPrograms[] = [
    {
      type: 'Mba',
      body: [
        {

          title: ' Business Administration'
        },
        {
     
            title: 'Management Systems Administration'
        },
        {
          title: 'Business Continuity Management'
        },
        {
    
          title: 'Information Security Management'
        },
        {
          title: 'IT Service Management'
        },
      ]
      
    },

    {
      type: 2,
      body: [
        {
          title: ' Business Continuity'
        },
        {
        title: 'Information Security Management'
      },
      {
        title: 'Information Technology Service Management'
      },
      {
        title: 'Quality Management'
      },
      {
        title: 'Risk Management'
      },
      ]
    }
    
  ]

  // form step 5 body text

  export const ESSAYDESCRIPTION: any[] = [
     {
      description: ` Please choose one of the following topics and write an essay of 700-1000 words. Indicate first the number of the topic you are choosing to answer and then begin writing. <br>
      <br>
       1. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis, esse? Sit placeat ipsam autem, perspiciatis id doloribus amet vero iure provident aspernatur consequatur officiis natus fugit ratione odio, veritatis possimus? <br>
       <br>
       2. Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic accusantium eligendi doloribus sequi nulla quod aperiam molestias unde maiores ipsa odio culpa nam, incidunt minus ex accusamus nobis quae illum! <br> 
       <br>
       3. Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo repellat voluptatibus architecto? Laborum, sequi neque culpa dolores alias eveniet quod tempore libero qui? Dolore facere omnis provident nesciunt? Eaque pariatur autem magnam facere excepturi hic sed ipsa error eos at. <br>
       <br>
       4. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis, esse? Sit placeat ipsam autem, perspiciatis id doloribus amet vero iure provident aspernatur consequatur officiis natus fugit ratione odio, veritatis possimus? <br>
       <br>`
     }
  ]

  export const DATAPRIVACY: any = {
    dataprivacy: `
          The information that you provide on your application form will be used for the following purpose: <br>
          <br>
          -To enable  your application for entry to be considered and allow our Admissions Committee, where applicable, to assist you through the application process; <br>
          <br>
          -To enable the University to compile statistics, or to assists other organizations to do so. No statistical information will be published that would indentify you personally. <br>
          <br>
          - To enable the University to initiate your student record should you be offered a place at the University.
    `
  }


  export const SUBPROGRAMMBA: Programs[] = [
    {

      title: ' Business Administration'
    },
    {
 
        title: 'Management Systems Administration'
    },
    {
      title: 'Business Continuity Management'
    },
    {

      title: 'Information Security Management'
    },
    {
      title: 'IT Service Management'
    },
  ]

  export const SUBPROGRAMGRADUATE: Programs[] = [
      {
        title: ' Business Continuity'
      },
      {
      title: 'Information Security Management'
    },
    {
      title: 'Information Technology Service Management'
    },
    {
      title: 'Quality Management'
    },
    {
      title: 'Risk Management'
    },
  ]


  




//BOTTOM FOOTER 
  //BOTTOM FOOTER MENU
 export const  BOTTOMFOOTERMENUS: bottomFooterMenu[] = [
    {
      title: 'Home',
      link: '#'
    },
    {
      title: 'About',
      link: '#'
    },
    {
      title: 'Academics',
      link: '#'
    },
    {
      title: 'ADMISSIONS',
      link: '#'
    },
    {
      title: 'STUDENT SERVICES',
      link: '#'
    },
    {
      title: 'NEWS & EVENTS',
      link: '#'
    },
    {
      title: 'ONLINE RESOURCES',
      link: '#'
    },
  ]

  //LEFT BOTTOM FOOTER
  export const BOTTOMFOOTERPCB: bottomFooterPecb = {
    content: '© 2019 PECB University All rights reserved'
  }

 export const PROGRAMRECORDS: programRecords[] = [
 {
   title: 'MBA - BUSINESS CONTINUITY MANAGEMENT',
   bodyPrograms:{
     menuPrograms:[
      {
        details: 'Program Duration:', 
        description:'12 to 36 months'
      },
      {
        details: 'Instruction Method:', 
        description:'Distance and in-Class Learning'
      },
      {
        details: 'Availability:', 
        description:'National and International Students'
      },
      {
        details: 'Language Medium:', 
        description:'English'
      },
      {
        details: 'Prerequisites:', 
        description:'Bachelors degree from an appropriately accredited institution and at least two years of relevant professional experience'
      }
    ],
    programContent:[
      {
        title: "Your bright future is our mission",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
      },
      {
        title: "Your bright future ",
        description: "Lorem donec massa sapien faucibus et. Consectetur lorem donec massa sapien faucibus et molestie ac feugiat. Dolor sit amet consectetur adipiscing. Tincidunt lobortis feugiat vivamus at augue eget arcu dictum. Mattis molestie a iaculis at erat. Mi sit amet mauris commodo. A diam maecenas sed enim ut sem viverra. Nascetur ridiculus mus mauris vitae ultricies. Diam vel quam elementum pulvinar etiam non quam lacus. ",
      },
    ]
  }
  }
 ];


export const TREE_DATA: Academics[] = [
  {
    name: 'MBA Programs',
    children: [
      {name: 'Program 1'},
      {name: 'Program 2'},
    ]
  },

  {
    name: 'Graduate Certificates',
    children: [
      {name: 'GC 1'},
      {name: 'GC 2'},
    ]
  }, 
  
  {
    name: 'University Catalog',
    children: [
      {name: 'UC 1'},
      {name: 'UC 2'},
    ]
  }, 
  
  {
    name: 'List of Courses',
    children: [
      {name: 'C 1'},
      {name: 'C 2'},
      {name: 'C 3'},
    ]
  },
  
  {
    name: 'Library',
    children: [
      {name: 'L 1'},
      {name: 'L 2'},
      {name: 'L 3'},
      {name: 'L 4'},
    ]
  }, 
  
  {
    name: 'Academic Calendar',
    children: [
      {name: 'AC 2'},
      {name: 'AC 3'},
      {name: 'AC 4'},
    ]
  }, 
  
  {
    name: 'Academic Affairs',
    children: [
      {name: 'AA2'},
      {name: 'AA3'},
      {name: 'AA4'},
    ]
  }, 
  
  {
    name: 'Online Learning',
    children: [
      {name: 'OL1'},
    ]
  },
];




