import { Component, OnInit } from '@angular/core';
import { ProgramsService } from '../../../services/programs.service';
import { programRecords } from '../../../model/program';
 
@Component({
  selector: 'app-program',
  templateUrl: './program.component.html',
  styleUrls: ['./program.component.scss']
})
export class ProgramComponent implements OnInit {

  constructor(
    private programsService: ProgramsService
  ) { }
  
  programDatas: programRecords[];

  displayedColumns: string[] = ['details', 'description'];

  ngOnInit() {
    this.getProgramsTable();
  }

  getProgramsTable(): void {
    this.programsService.getProgramTableDate().subscribe(
      programDatas => this.programDatas = programDatas
    )
  }

}
