import { Component, OnInit } from '@angular/core';

import { topStories, topStoriesHeader } from '../../../model/topstories';
import { HomeService } from '../../../services/home.service';
import { NewsEventService } from 'src/app/web/services/news-event.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-top-stories',
  templateUrl: './top-stories.component.html',
  styleUrls: ['./top-stories.component.scss']
})
export class TopStoriesComponent implements OnInit {

  
  

  //TOP STORIES DATA ON CAROUSEL
  topStoriesHeader: topStoriesHeader[];
  topStoriesDatas: topStories[];
  topStoriesDatasRes;

  imageUrl = environment.uploadFilesUrl;

  //TOP STORIES CAROUSEL OPTION
  myCarouselOptions={
    margin:15,
    stagePadding: 0,
    dots: false, 
    nav: true,
    navText: [
      "<i class='fas fa-chevron-circle-left'></i>",
      "<i class='fas fa-chevron-circle-right'></i>"
  ],
  responsive:{
    0:{
      items:1
    },
    680:{
      items:2
    },
    960:{
      items:3
    }
  }
  };
 

  constructor(
    private homeService: HomeService,
    private newsEventService: NewsEventService,
  ) { }

  ngOnInit() {
    setTimeout(()=> this.getTopStories(), 1500);
    
    this.getTopStoriesHeader();
  }

  getTopStories(): void {
    this.newsEventService.getBlogNews().subscribe( res => {
      this.topStoriesDatasRes = res;
      this.topStoriesDatas = this.topStoriesDatasRes.data;
      //console.log(this.topStoriesDatas);
      
    }
      
   )
 }

// getTopStories(): void {
//   this.homeService.getTopStoriesData().subscribe(
//     topStoriesDatas => this.topStoriesDatas = topStoriesDatas
//  )
// }




  getTopStoriesHeader(): void {
    this.homeService.getTopStoriesHeaderData().subscribe(
      topStoriesHeader => this.topStoriesHeader = topStoriesHeader
    )
  }

}
