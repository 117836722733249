import { Component, OnInit, OnDestroy, ViewEncapsulation  } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { Subscription } from 'rxjs';

import {  footerMenu } from '../../../model/footer';
import { PagesService } from '../../../services/pages.service';


import { environment } from '../../../../../environments/environment';
import { Title, Meta  } from '@angular/platform-browser';


@Component({
  selector: 'app-text-pages',
  templateUrl: './text-pages.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./text-pages.component.scss'],
  
})
export class TextPagesComponent implements OnInit, OnDestroy  {

  sidebarMenus: footerMenu[];
  sidebarMenusRes:any;

  

  id: any;
  slug: any;

  // image base url
  imageBaseUrl = environment.uploadFilesUrl;


  //pages Item main res
  pagesItemRes: any;
  //pages Item data
  pagesItem: any;

  panelOpenState = false;

  private httpSubscription: Subscription;

  constructor(

    private pagesService: PagesService,
    private route: ActivatedRoute,
    private titleService: Title,
    private meta: Meta,
  ) { }

  ngOnInit() {
    this.getSideBarMenu();

    //taking slug from url
    this.route.paramMap.subscribe(params => {
      this.slug = params.get('slug'); 
      //calling pages item from pages service 
      this.httpSubscription = this.pagesService.getPageItem(this.slug).subscribe(res => {
      this.pagesItemRes = res;
      this.pagesItem = this.pagesItemRes.data;
      this.titleService.setTitle('PECB University-' + this.pagesItem.title)
      this.meta.addTags([
        {name: 'keywords', content: this.pagesItem.description},
        {name: 'description', content: this.pagesItem.description},
        {name: 'canoncal_link', content: this.pagesItem.canoncal_link},
        {name: 'redirect', content: this.pagesItem.redirect},
        ]);
      //console.log(this.pagesItem.relatedBrochures);
    
   }); 
     
    });

 
  

    
      
  }

  ngOnDestroy() {
    this.httpSubscription.unsubscribe();
  }

  //SIDEBAR MENU
 getSideBarMenu(): void {
  this.pagesService.getSidebarMenu().subscribe(
    res => {
      this.sidebarMenus = res
    //  console.log(this.sidebarMenus);
      
      
    }
 )
}




}
