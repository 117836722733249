import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { Subscription } from 'rxjs';

import { environment } from '../../../environments/environment';
import { footerMenu } from '../model/footer';
import { ProgramsService } from '../services/programs.service';
import { Title, Meta  } from '@angular/platform-browser';


@Component({
  selector: 'app-program-items',
  templateUrl: './program-items.component.html',
  styleUrls: ['./program-items.component.scss']
})
export class ProgramItemsComponent implements OnInit {

  sidebarMenus: footerMenu[];
  sidebarMenusRes:any;


  slug: any;

  // image base url
  imageBaseUrl = environment.uploadFilesUrl;


  //pages Item main res
  pagesItemRes: any;
  //pages Item data
  pagesItem: any;

  panelOpenState = false;


  constructor(
    private programsService: ProgramsService,
    private route: ActivatedRoute,
    private titleService: Title,
    private meta: Meta,
  ) { }
  

  ngOnInit() {
    this.getSideBarMenu();

  //taking id from url
  this.route.paramMap.subscribe(params => {
      this.slug = params.get('subprogram');
     // console.log(this.slug);
    // get program Item
      this.getProgramItem();
      
  });
}  

// get program item 
 getProgramItem() {
  this.programsService.getSubProgramItem(this.slug).subscribe(res => {
    this.pagesItemRes = res;
    this.pagesItem = this.pagesItemRes.data;
    //console.log(this.pagesItem);
    
    this.titleService.setTitle('PECB University-' + this.pagesItem.title)
      this.meta.addTags([
        {name: 'keywords', content: this.pagesItem.description},
        {name: 'description', content: this.pagesItem.description},
        {name: 'canoncal_link', content: this.pagesItem.canoncal_link},
        {name: 'redirect', content: this.pagesItem.redirect},
        ]);
   });  
 }

//SIDEBAR MENU
getSideBarMenu(): void {
  this.programsService.getSidebarMenu().subscribe(
    res => {
      this.sidebarMenus = res
      // console.log(res)
    //  console.log(this.sidebarMenus);
    }
 )
}

}
