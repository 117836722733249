import { Component, OnInit } from '@angular/core';


declare const $: any;
declare interface RouteInfo {
    path: string;
    id: string;
    title: string;
    icon: string;
    class: string;
    submenu: any;
}
export const ROUTES: RouteInfo[] = [
    { path: '/dashboard/home', id: '', title: 'Dashboard',  icon: 'dashboard', class: '', submenu: ''
     },
   // { path: '/user-profile', id: '', title: 'User Profile',  icon:'person', class: '', submenu: '' },
   // { path: '/table-list', id: '', title: 'Table List',  icon:'content_paste', class: '', submenu: '' }, manage-progams
  
    { path: 'javascript:void(0)', id: '#manage', title: 'Manage',  icon:'touch_app', class: 'MenuSub',  
      submenu: [
        {
          title: 'Main Menu',
          path: 'create-menu',
          id: '',
          icon:'arrow_forward'
        },
          {
        title: 'Social Media Icons',
        path: 'social-icons',
        id: '',
        icon:'arrow_forward'
      },
      {
        title: 'Logo/University Info',
        path: 'main-logo',
        id: '',
        icon:'arrow_forward'
      }, 
      {
        title: 'Main Slider',
        path: 'main-slider',
        id: '',
        icon:'arrow_forward'
      }, 
      {
        title: 'Headers Image',
        path: 'manage-headers',
        id: '',
        icon:'arrow_forward'
      },
      {
        title: 'First Top Menu',
        path: 'first-top-menu',
        id: '',
        icon:'arrow_forward'
      }, 
      {
        title: 'Apply widget',
        path: 'apply-widget',
        id: '',
        icon:'arrow_forward'
      }, 
      {
        title: 'Welcome Section ',
        path: 'welcome-section',
        id: '',
        icon:'arrow_forward'
      },
      {
        title: 'Coupons',
        path: 'coupon',
        id: '',
        icon:'arrow_forward'
      },
      {
        title: 'Brochures',
        path: 'brochures',
        id: '',
        icon:'arrow_forward'
      },
      {
        title: 'Sidebar Menu',
        path: 'sidebar-menu',
        id: '',
        icon:'arrow_forward'
      },
      {
        title: 'Staff',
        path: 'manage-staff',
        id: '',
        icon:'arrow_forward'
      },
      {
        title: 'Contact Staff',
        path: 'manage-contact-staff',
        id: '',
        icon:'arrow_forward'
      },

      {
        title: 'FAQ',
        path: 'faq-manage',
        id: '',
        icon:'arrow_forward'
      },
      {
        title: 'Footer Menu',
        path: 'footer-menu',
        id: '',
        icon:'arrow_forward'
      },
      

  
]},
{ path: 'javascript:void(0)', id: '#page', title: 'Pages',  icon: 'assignment', class: 'MenuSub', submenu: [
  {
    title: 'Pages',
    path: 'pages',
    id: '',
    icon:'arrow_forward'
  },
  {
    title: 'Create Page',
    path: 'manage-pages',
    id: '',
    icon:'arrow_forward'
  },
  {
    title: 'Tags',
    path: 'manage-pages/tags',
    id: '',
    icon:'arrow_forward'
  },
 ]
   },
   { path: 'javascript:void(0)', id: '#manage-p', title: 'Manage Programs',  icon:'class', class: 'MenuSub',  
   submenu: [
     { 
      title: 'Programs',
      path: 'manage-programs', 
      id: '',
      icon:'arrow_forward'
     },
     {
      title: 'Program Items',
      path: 'manage-programs/items', 
      id: '',
      icon:'arrow_forward'
     },
     {
      title: 'Create program Items',
      path: 'manage-programs/create-items', 
      id: '',
      icon:'arrow_forward'
     },
   ]
  },
{ path: 'javascript:void(0)', id: '#form', title: 'Manage Forms',  icon:'library_books', class: 'MenuSub',  
submenu: [ 
  {
    title: 'Candidates',
    path: 'application-candidates',
    id: '',
    icon:'library_books'
  },
  {
    title: 'Application Form',
    path: 'application-form-manage',
    id: '',
    icon:'library_books'
  },
  {
    title: 'Application Form Details',
    path: 'form-supporting-details',
    id: '',
    icon:'library_books'
  },  
  {
    title: 'Form Personal Statement',
    path: 'form-personal-statement',
    id: '',
    icon:'library_books'
  },
  {
    title: 'Scholarship Candidates',
    path: 'scholar-candidates',
    id: '',
    icon:'library_books'
  },
  {
    title: 'Countries/States',
    path: 'manage-countries-states',
    id: '',
    icon:'library_books'
  },
  {
    title: 'Express interest',
    path: 'manage-express-form',
    id: '',
    icon:'library_books'
  },
  {
    title: 'Contact Us Form',
    path: 'manage-contact-form',
    id: '',
    icon:'library_books'
  },
  {
    title: 'Email Templates',
    path: 'email-template',
    id: '',
    icon:'library_books'
  }, 
  {
    title: 'Subscribers',
    path: 'subscribers',
    id: '',
    icon:'library_books'
  },
  
]},
{ path: 'javascript:void(0)', id: '#manage-b', title: 'Blog & News',  icon:'class', class: 'MenuSub',  
submenu: [
  { 
   title: 'Blog & news',
   path: 'manage-blog-news', 
   id: '', 
   icon:'arrow_forward'
  }, 
  { 
    title: 'Create Blog & News',
    path: 'manage-blogs-news/create', 
    id: '',
    icon:'arrow_forward'
   }
   
 
]
},
  //  { path: '/icons', title: 'Icons', id: '',  icon:'bubble_chart', class: '', submenu: '' },
 //   { path: '/maps', title: 'Maps', id: '',  icon:'location_on', class: '', submenu: '' },
    // { path: 'dashboard', id: '', title: 'Notifications',  icon:'notifications', class: '', submenu: '' },
   
   // { path: '/upgrade', id: '', title: 'Upgrade to PRO',  icon:'unarchive', class: 'active-pro', submenu: '' },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  closeMenu: boolean = false;
  selectedItem= '';

  constructor() { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }
  isMobileMenu() {
      // if ($(window).width() > 991) {
      //     return false;
      // }
      return true;
  };


  //toogle subemnus
  toggleMenu(i) {
    

    if( this.selectedItem == i) {
        this.selectedItem = '';
        return;
    }
    this.selectedItem = i;
  }

 
}
