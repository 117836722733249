import { Component, OnInit } from '@angular/core';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener,MatTreeNestedDataSource} from '@angular/material/tree';
import {NestedTreeControl} from '@angular/cdk/tree';
import { TREE_DATA } from 'src/app/web/data-mock';

interface Academics {
  name: string;
  children?: Academics[];
}


@Component({
  selector: 'app-academics-tree',
  templateUrl: './academics-tree.component.html',
  styleUrls: ['./academics-tree.component.scss']
})
export class AcademicsTreeComponent implements OnInit {
  treeControl = new NestedTreeControl<Academics>(node => node.children);
  dataSource = new MatTreeNestedDataSource<Academics>();
  ngOnInit() {
  }

  
  constructor() {this.dataSource.data = TREE_DATA;}
  hasChild = (_: number, node: Academics) => !!node.children && node.children.length > 0;




}
