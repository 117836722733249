import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { forkJoin } from 'rxjs';

import { environment } from '../../../environments/environment';

import { SocialMediaIcons, Menu, MainMenu, LogoPath } from '../model/navbar';

import {  MAINMENU,
          FIRSTTOPMENU,
          //SOCIALMEDIAICON, 
          LOGOPATH,
      } from '../data-mock';

@Injectable({
  providedIn: 'root'
})




export class NavbarService {

  constructor(
    private http: HttpClient
  ) {
    
   }

   apiUrl = environment.apiUrl;

   configUrl = this.apiUrl+'socialMedia';

   mainMenuUrl = this.apiUrl+'showFullMenu';

   firstTopMenu = this.apiUrl+'quickLink';

   logoPathUrl = this.apiUrl+'logo';

   imageUrl = environment.uploadFilesUrl;

  // NAVBAR DATA
  
  //SOCIAL MEDIA ICONS
  // getSocialMediaIcons(): Observable<SocialMediaIcons[]> {
  //   return this.http.get<SocialMediaIcons[]>(this.configUrl);
  // }




  // getfirstTopMenuData(): Observable<MainMenu[]> {
  //   return this.http.get<MainMenu[]>(this.firstTopMenu);
  // }

  // getMainMenuData(): Observable<Menu[]> {
  //   return this.http.get<Menu[]>(this.mainMenuUrl);
  // }

  getLogoPathData(): Observable<LogoPath> {
    return this.http.get<LogoPath>(this.logoPathUrl);
  }

  // Make all api request at one function
  public requestDataFromMultipleSources(): Observable<any[]> {

    // main menu http api call 
    let mainMenu = this.http.get<Menu[]>(this.mainMenuUrl);

    // main logo path api call
    let logoPath = this.http.get<any>(this.logoPathUrl);

    // social media icons api call
    let socialMedia = this.http.get<SocialMediaIcons[]>(this.configUrl);

    //first top menu api call
    let firstTopMenu = this.http.get<MainMenu[]>(this.firstTopMenu);
    
    return forkJoin([mainMenu, firstTopMenu, socialMedia, logoPath]);
  }
           

   
}
