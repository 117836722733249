import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  privateapiUrl = environment.apiUrl;

  private baseUrl = this.privateapiUrl;

  constructor(
    private http: HttpClient,
  ) { }

  login(data) {
    const headers = new HttpHeaders();
  
  // headers.append('Content-Type', 'multipart/form-data');
  // headers.append('Accept', 'application/json');

  return this.http.post(`${this.baseUrl}login`, data);
  }

  register(data) {
    return this.http.post(`${this.baseUrl}register`, data);
  }
}
