import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, FormArray, NgForm, Validators } from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatDialog } from '@angular/material';
import { MatDialogRef } from '@angular/material';
import { environment } from '../../../environments/environment';


import { SocialMediaIcons } from '../model/navbar';
import { FlashnotificationService } from '../services/flashnotification.service';

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss']
})

export class DeleteDialogComponent implements OnInit {

  apiUrl = environment.apiUrl;
  
  socialMediaUrl = this.apiUrl+'socialMedia';

  mainUrl = this.data.url;

  constructor(
    public thisDialogRef: MatDialogRef<DeleteDialogComponent>,
    private http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private flashNotification: FlashnotificationService,
  ) { }

  ngOnInit() {
    
  }


  deleteSocialMediaIcon(id) {
    
    this.http.delete(this.mainUrl + '/' + id).subscribe(res => {
      //console.log(res);
      setTimeout(()=>this.flashNotification.showNotification('Succesfully Deleted', 'danger'), 1500);
    }, err => {
        this.flashNotification.showNotification('Error occurred', 'danger');
        if(err.error.message) {
          this.flashNotification.showNotification(err.error.message, 'danger'); 
        }
    });
  
  }

  // onCloseCancel() {
  //   this.thisDialogRef.close('Cancel');
  // }

  // onCloseConfirm(id) {
  //   this.deleteSocialMediaIcon(id);
  //   this.thisDialogRef.close('Delete');
  // }

  
  

}
