import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { NewsEventService } from '../services/news-event.service';
import { environment } from 'src/environments/environment';
import { footerMenu } from '../model/footer';
import { ProgramsService } from '../services/programs.service';
import { Title, Meta  } from '@angular/platform-browser';



@Component({
  selector: 'app-news-events-items',
  templateUrl: './news-events-items.component.html',
  styleUrls: ['./news-events-items.component.scss']
})
export class NewsEventsItemsComponent implements OnInit {
  
  sidebarMenus: footerMenu[];
  sidebarMenusRes:any;

  slug: any;

  // image base url
  imageBaseUrl = environment.uploadFilesUrl;


  //blog news Item main res
  blogNewsItemRes: any;

  //blog news item data
  pagesItem: any;

  constructor(
    private route: ActivatedRoute,
    private newsEventService: NewsEventService,
    private programsService: ProgramsService,
    private titleService: Title,
    private meta: Meta,
  ) { }

  ngOnInit() {

    //taking slug from url
  this.route.params.subscribe(params => {
    this.slug = params['slug'];
   // console.log(this.slug);
    this.getBlogNewsItem();
    this.getSideBarMenu();
});
  }


  // get program item 
 getBlogNewsItem() {
  this.newsEventService.getBlogNewsItem(this.slug).subscribe(res => {
    this.blogNewsItemRes = res;
    this.pagesItem = this.blogNewsItemRes.data;
      //console.log(this.pagesItem);
      this.titleService.setTitle('PECB University -' + this.pagesItem.title)
      this.meta.addTags([
        {name: 'keywords', content: this.pagesItem.description},
        {name: 'description', content: this.pagesItem.description},
        {name: 'canoncal_link', content: this.pagesItem.canoncal_link},
        {name: 'redirect', content: this.pagesItem.redirect},
        ]);
      
   }); 
 }

 //SIDEBAR MENU
getSideBarMenu(): void {
  this.programsService.getSidebarMenu().subscribe(
    res => {
      this.sidebarMenus = res
    //  console.log(this.sidebarMenus);
    }
 )
}

}
