import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes }  from '@angular/router';

import { HomeComponent } from './web/components/home-components/home/home.component';
import { ProgramComponent } from './web/components/program-components/program/program.component';
import { NewsEventsComponent } from './web/components/news-events/news-events.component';
import { SubmenusPageComponent } from './web/components/submenus/submenus-page/submenus-page.component';
import { TextPagesComponent } from './web/components/submenus/text-pages/text-pages.component';
import { SubmenuStaffComponent } from './web/components/submenus/submenu-staff/submenu-staff.component';
import { ExpressInterestComponent } from './web/components/forms/express-interest/express-interest.component';
import { AdminComponent } from './admin/admin.component';
import { NotFoundComponent } from './web/not-found/not-found.component';


const appRoutes: Routes = [
  // {path: '', component: HomeComponent},
  // {path: 'program', component: ProgramComponent},
  // {path: 'news-events', component: NewsEventsComponent},
  // {path: 'submenus-page',component: SubmenusPageComponent},
  // {path: 'submenus-text-page', component: TextPagesComponent},
  // {path: 'submenu-staff/:name', component: SubmenuStaffComponent},
  // {path: 'express-interest', component: ExpressInterestComponent},
  
  // {
  //   path: '',
  //   children: [{
  //     path: '',
  //     loadChildren: './admin/admin.module#AdminModule'
  //   }],
  // },
  // {
  //   path: '',
  //   children: [{
  //     path: '',
  //     loadChildren: './web/web.module#WebModule'
  //   }]
  // }
  
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(
      appRoutes,
      {scrollPositionRestoration: 'enabled'}
    )
  ],
  exports: [
      RouterModule
    ]
})
export class AppRoutingModule { }
