import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';


@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {



  constructor(private router: Router) { }
  

  

  ngOnInit() {
    
  }

  
// scroll view on the top after routes is clicked
  changeOfRoutes() {

     this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) { 
        let el = document.getElementById('top');
     el.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
      }
    });
}

 


}


