import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PageHeaderService } from 'src/app/web/services/page-header.service';
import { PagesService } from 'src/app/web/services/pages.service';

@Component({
  selector: 'app-leadership-committees',
  templateUrl: './leadership-committees.component.html',
  styleUrls: ['./leadership-committees.component.scss']
})
export class LeadershipCommitteesComponent implements OnInit {

  imageUrl = environment.uploadFilesUrl;

  pageHeaderImage;

  sidebarMenus:any[];

  constructor(
    private pageHeaderService: PageHeaderService,
    private pagesService: PagesService,
  ) { }

  ngOnInit() {

    this.getSideBarMenu();
    this.getPageHeader();
  }


  getPageHeader(): void {
    this.pageHeaderService.getImageHeader('staff').subscribe(
      res => {
        this.pageHeaderImage = res;
        // console.log(this.pageHeaderImage.image);
      } 
   )
  }


   //FOOTER MAIN MENU
 getSideBarMenu(): void {
  this.pagesService.getSidebarMenu().subscribe(
    res => {
      this.sidebarMenus = res
    //  console.log(this.sidebarMenus);
      //console.log(this.sidebarMenus);
      
    }
 )
}

}
