import { Directive } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[appScholarMaxWords]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: ScholarMaxWordsDirective,
    multi: true
  }]
})
export class ScholarMaxWordsDirective {


  validate(control: AbstractControl) : {[key: string]: any} | null {
    if (control.value && control.value.replace(/\s+/g, ' ').split(" ").length < 150 || control.value && control.value.replace(/\s+/g, ' ').split(" ").length > 300 ) {
      return { 'maxWords': true }; // return object if the validation is not passed.
    }
    return null; // return null if validation is passed.
  }

  constructor() { }

}
