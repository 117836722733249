import { Component, OnInit } from '@angular/core';

import { offeringSnapshot } from '../../../model/offering-snapshot';

import { ProgramsService } from '../../../services/programs.service';


import {  footerMenu } from '../../../model/footer';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
import { PageHeaderService } from 'src/app/web/services/page-header.service';

import { Title, Meta  } from '@angular/platform-browser';

@Component({
  selector: 'app-submenus-page',
  templateUrl: './submenus-page.component.html',
  styleUrls: ['./submenus-page.component.scss']
})
export class SubmenusPageComponent implements OnInit {

  offeringPrograms: offeringSnapshot[];
  sidebarMenus: footerMenu[];

  slug: any;


  pageHeaderImage;

  
  // image base url
  imageBaseUrl = environment.uploadFilesUrl;


  //pages Item main res
  programItemRes: any;
  //pages Item data
  programItem: any;

  private parametersObservable: any;

  params: Subject<any>;

  constructor(
    private programsService: ProgramsService,
    private route: ActivatedRoute,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private pageHeaderService: PageHeaderService,
    private titleService: Title,
    private meta: Meta,
  ) { 
   
  }

  ngOnInit() {

    //taking slug from url
    this.activeRoute.paramMap.subscribe(params => {
    this.slug = params.get('slug'); 
    this.getProgram(this.slug);
    // this.slug = this.route.snapshot.data['subprogram'];

  });

  
  
  this.getPageHeader();

    //side bar menu
   this.getSideBarMenu();

    // this.slug = 'mba-programs';

     // get program from service
    
      
  }

  // get program from service
  getProgram(slug) {
    this.programsService.getProgramItem(slug).subscribe(res => {
      this.programItemRes = res;
      this.programItem = this.programItemRes.data;
      this.titleService.setTitle(this.programItem.name)
      this.meta.addTags([
        {name: 'keywords', content: 'PECB University Programs'},
        {name: 'description', content: 'PECB University is committed to providing an experience that spawns its students’ intellectual horizon, and helping them to acquire various primed skills for successful adaptation in the business world. Your journey as a PECB University student allows you to explore multiple prominent disciplines, and prepares you to become a highly qualified professional in the chosen industry. We will continue the grit and determination to provide you with superior education while teaching relevant subjects that aid you in becoming critical, independent, and daring professionals'},
        ]);
    })
  }

  //navigate to subprogram
  onSubProgram(slug) {
   //console.log(slug);
    
    this.router.navigate([this.router.url, slug]);
  }


 //sidebar  MENU
 getSideBarMenu(): void {
  this.programsService.getSidebarMenu().subscribe(
    res => {
      this.sidebarMenus = res;
      //console.log(this.sidebarMenus);
      
    } 
 )
}

// get page header
getPageHeader(): void {
  this.pageHeaderService.getImageHeader('programs').subscribe(
    res => {
      this.pageHeaderImage = res;
      // console.log(this.pageHeaderImage.image);
      

    } 
 )
}

}
