import { Component, OnInit } from '@angular/core';

import { HomeWidgets } from '../../../model/home-widgets';
import { HomeService } from '../../../services/home.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-widgets-applying',
  templateUrl: './widgets-applying.component.html',
  styleUrls: ['./widgets-applying.component.scss']
})
export class WidgetsApplyingComponent implements OnInit {

  
  // WIDGETS DATA
  widgets: any;
  wigdgetsRes: any;


  // applyWidgetimageURl = 'http://127.0.0.1:8000/uploads/';

  // image base url
  imageBaseUrl = environment.uploadFilesUrl;

  //
  callAPi: boolean;

  constructor(
    private homeService: HomeService
  ) { }

  ngOnInit() {
    setTimeout(()=> this.getWidgets(), 2000);
   // window.addEventListener('scroll', this.scroll, this.callAPi = false); //third parameter
  }

  scroll = (event: any): void => {
   // console.log('called');
    
      if(this.callAPi == false) {
       // console.log('aaa');
        
        this.getWidgets();
        this.callAPi = true
      }
  };


  getWidgets(): void {
    this.homeService.getWidgetsSectionData().subscribe( res => {
        this.wigdgetsRes = res;
        this.widgets = this.wigdgetsRes.data;
    }
      //widgets => this.widgets = widgets
   )
  }

}
