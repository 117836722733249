import { Component, OnInit } from '@angular/core';

import { SliderService } from '../../../services/slider.service';
import { Slider } from '../../../model/slider';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {

  // mySlideImages = [1,2,3].map((i)=> `https://picsum.photos/640/480?image=${i}`);
  // myCarouselImages =[1,2,3,4,5,6].map((i)=>`https://picsum.photos/640/480?image=${i}`);

  public showContent: boolean = false;

   // TOP SLIDER CONTENT
  sliderContents: Slider[]; 
  sliderContentRes: any;

  imageUrl = environment.uploadFilesUrl;

  mySlideOptions={items: 1, dots: true, nav: true, margin: 15, loop:true, autoplay:true,
    autoplayTimeout:5000, autoplayHoverPause:true, animateOut: 'fadeOut', smartSpeed:450};

  constructor(
    private sliderService: SliderService
  ) { }

  ngOnInit() {
    this.getSliderContent();
    setTimeout(()=>this.showContent=true, 2000);
    
  }

  

  getSliderContent(): void {
    this.sliderService.getSliderContentData().subscribe(
     res => {
       
       this.sliderContentRes = res;
       this.sliderContents =this.sliderContentRes.data;
       //console.log(this.sliderContents);
     }
   )
 }

// getSliderContent(): void {
  
//   this.sliderService.getSliderContentData().subscribe(
//     sliderContents => {
//       this.sliderContents = sliderContents
//       console.log(this.sliderContents);
//     }
    
//  )
// }





}
