import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class PagesService {

  //Base api url
  apiUrl = environment.apiUrl;

  // Pages api url
  pageItemUrl = this.apiUrl + 'showFullPageBySlug/'

  // Sidebar menu urll
  sidebarMenuUrl = this.apiUrl+ 'showFullSideMenu'

  constructor(
    private http: HttpClient
  ) { }

  getPageItem(slug): Observable<any> {
    return this.http.get<any>(this.pageItemUrl + slug);
  }

  // side bar menu on pages
  getSidebarMenu(): Observable<any> {
    return this.http.get(this.sidebarMenuUrl);
  }

}
